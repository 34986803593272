import React, {useState, useEffect, useRef} from 'react'
import { useParams, useNavigate } from "react-router-dom";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'

import { API } from "../../services/api";



import FONDOLOBY from './../../imagenes/svg/FONDOLOBY.svg';
import ANIMACIONFLY from './../../imagenes/ANIMACIONFLY.png';
import ANIMACIONFLY2 from './../../imagenes/ANIMACIONFLY2.png';


import potterMandragoraM from './../../imagenes/pokemonGirasolM.png';

import './Lobi.css';

import Navbar from './../../pages/Navbar/Navbar';
import audiomp3 from './../../audio/Marry_You_Bruno_Mars_Naijapals.mp3';

import hamburg from './../../imagenes/bar-de-hamburguesas.png';

const Lobi = () => {
  const audioRef = useRef(null);
  const [locpLay, setlocpLay] = useState(audiomp3);
    
  const [imgVolumenShow, setimgVolumenShow] = useState(false);

    const [Invitados, setInvitados] = useState([
        {
			"_id": "65509cd1440078b3ebac8934",
			"nombreCompleto": "ERUDITO LORO PERPACIO",
			"username": "Lorito",
			"idioma": "espana",
			"createdAt": "2023-11-12T09:37:21.389Z",
			"updatedAt": "2024-02-16T14:47:22.680Z",
			"__v": 0,
			"email": "adrian.sanz.diaz@gmail.com",
			"id_avatares": {
				"_id": "65786b259653cdc512ccdb12",
				"name": "firasolZombie",
				"imagen1": "firasolZombie.svg",
				"imagen2": "firasolZombie.png",
				"createdAt": "2023-12-12T14:16:05.114Z",
				"updatedAt": "2024-01-06T18:58:11.851Z",
				"__v": 0
			}
        }]);


      //TEXTOS INCIIALES
    const [IdiomaTextActual, setIdiomaTextActual] = useState({
      "_id": "6599cac40db07abe9ffb9e96",
			"idioma": "espana",
			"cargamusicatext": "¡Sube el volumen!",
			"iconoContinuar": "BTNMUSICESP.png",
			"iconoContinuarsvg": "BTNMUSICESP.svg",
			"imgNoscasamos": "noscasamos.png",
			"titleswalsininv": "No se quién eres\n\n😷\n",
			"textswalsininv": "Debes acceder por el enlace que te hemos enviado. Lo siento.",
			"buttonswalsininv": "¡OK!😒",
			"titleswalfirma": "Necesitamos una firmita por aqui…",
			"buttonswalfirmacancel": "Cerrar",
			"buttonfirmalimpiar": "Limpiar",
			"buttonfirmaguardar": "Guardar",
			"textformlargo": "Todavía estamos ultimando algunos detalles de la boda. Por ello, necesitamos que nos confirmes tus datos de contacto, para poder avisarte de nuevas noticias sobre el evento.",
			"textoinput1": "Móvil:",
			"textoinput2": "Email:",
			"inputtextarea": "¿Se te ocurre algún otro avatar? ¡Dinos cuál! Recuerda que la temática es: flores, plantas…",
			"buttonconfirmacion": "Confirmar asistencia",
			"textosCarta": "Queridos amigos, ¡la hora ha llegado!\nAdrián y Majka, por fin casados.\nSerá en Lupiana, el 20 de septiembre,\nfirmando que su amor dure para siempre.\n\nNo falten a este día tan especial,\ncon baile, comida, ¡será genial!\nOs esperamos con cariño y emoción,\npara ser testigos de esta unión.",
			"textosCheckCarta": "Acepto las condiciones de la invitación",
			"textoCheck1": "Acepto bailar como si nadie estuviera mirando, incluso si todos lo están haciendo, aunque mis movimientos se asemejen más a un pingüino en patinaje sobre hielo",
			"textoCheck2": "Estoy de acuerdo en reírme hasta que me duela la barriga, aunque mi risa suene como una mezcla entre una hiena y un pato",
			"textoCheck3": "Acepto que las fotos embarazosas son una parte obligatoria de la celebración, y me uniré a la diversión sin quejarme, incluso si eso implica usar accesorios ridículos para fotos grupales",
			"textoCheck4": "Prometo no contar chistes malos, a menos que sean tan malos que se vuelvan buenos por accidente",
			"textoCheck5": "Acepto que la pista de baile será mi gimnasio y bailaré hasta quemar más calorías de las consumidas en la cena",
			"textoCheck6": "Acepto tomarme un chupito de Soplica para no hacerle un feo a la novia",
			"textoCheck7": "Estoy dispuesto a aplaudir, gritar \"vivan los novios\" y vitorear exageradamente, animando a los novios y demás invitados como si estuviera en un estadio deportivo",
			"textoCheck8": "Estoy de acuerdo en que los abrazos y besos son obligatorios, y cualquier intento de resistirse será castigado con abrazos y besos aún más fuertes",
			"textoCheck9": "Prometo no rechazar ninguna oferta de comida, postre o delicioso manjar que se cruce en mi camino durante la celebración",
			"textoCheck10": "Acepto que las risas y los recuerdos compartidos superarán cualquier intento de mantener la dignidad intacta",
			"textoCheck11": "Me comprometo a confirmar mi asistencia en fecha. Si no, me llevaré una silla y un sándwich",
			"textoFailCheckTitle": "Acepta, TODAS las condiciones.",
			"textoFailCheck": "Aqui, hemos venido a jugar.",
			"textoSucesCheckTitle": "Gracias por aceptar las condiciones",
			"textoSucesCheck": "Ahora solo queda que elijas tu avatar.",
			"createdAt": "2024-01-06T21:48:52.020Z",
			"updatedAt": "2024-01-21T22:25:53.666Z",
			"__v": 0,
			"cronometroText": "Faltan",
			"imagenLosdosText": "adrianymajkaSUN.png",
			"cronometroTextSegundos": "Seg.",
			"musicaText": "Activa el sonido",
			"textoTyC": "Condiciones de asistencia",
			"textoCarga": "Tenemos algo importante que deciros...\n¡Nos casamos!\n",
			"imagenSello": "sellocartaLogo.svg",
			"imagenSelloM": "sellocartaLogoON.svg"
    });

    useEffect(() => {
        if(localStorage.getItem("invitado")){
          const getInvitados = async () => {
            const userInv =  await API.get("/invitados").then((res) => {
              console.log("respuesta",res);
              setInvitados(res.data.invitados);
            })
          };
          if(localStorage.getItem("textos")){

            const filtrarPorIdioma = (elemento) => elemento.idioma === JSON.parse(localStorage.getItem("invitado")).idioma;
            const arrayTextos = JSON.parse(localStorage.getItem('textos'));
            const resultado = arrayTextos.filter(filtrarPorIdioma);
            setIdiomaTextActual(resultado[0]);
          }
          getInvitados();
        }else{
          Swal.fire({
            icon: 'error',
            title: IdiomaTextActual.titleswalsininv,
            text: IdiomaTextActual.textswalsininv,
            confirmButtonText: IdiomaTextActual.buttonswalsininv
          }).then(result => {
            if (result.isConfirmed) {
              window.location.replace('https://www.google.com/search?q=GNET+-+Grupalia+Internet+S.A.');
            }
         })
          
        }
        
      }, []);

      const [tiempoRestante, setTiempoRestante] = useState({});

      useEffect(() => {
        const intervalo = setInterval(() => {
           const fechaObjetivo = new Date('2024-09-20T18:00:00');
           const tiempoActual = new Date();
           const diferencia = fechaObjetivo - tiempoActual;
            /* console.log("hora actual", tiempoActual); */
           const segundos = Math.floor(diferencia / 1000);
           const minutos = Math.floor(segundos / 60);
           const horas = Math.floor(minutos / 60);
           const dias = Math.floor(horas / 24);
       
           setTiempoRestante({
             dias,
             horas: horas % 24,
             minutos: minutos % 60,
             segundos: segundos % 60,
             totalSegundos: Math.round(diferencia / 1000),
           });
        }, 1000);
       
        return () => clearInterval(intervalo);
       }, []);


       const [animacionFlyTurno, setanimacionFlyTurno] = useState(false);

       setTimeout(() => cambioTime(animacionFlyTurno), 4000)

       const cambioTime = (valor) => {
        if(animacionFlyTurno){
          setanimacionFlyTurno(false)
        }else{
          setanimacionFlyTurno(true)
        }
      };

      const generarMatriz = (invitado) => {
        
        /* const n = invitado.length; */
        const n = invitado.length;
        const row = n/4;
        const partesNumero = row.toString().split('.');
        const parteEntera = partesNumero[0] || 0;
        const parteDecimal = partesNumero[1] || 0;

        switch (parteDecimal) {
          case '25':
            var contador = 1
            break;
          case '5':
            var contador = 2
            break;
          case '75':
            var contador = 3
            break;
          default:
            var contador = 4;
        }
        const rowUltimo = parteEntera;
        
        const matriz = [];
        let numero = 0;
        
        

        for (let i = 0; i < row; i++) {
          console.log("matriz1", invitado[numero]);
          const fila = [];
          
          if(i == rowUltimo){
            for (let j = 0; j < contador; j++) {
              console.log("matriz", contador);
              fila.push(invitado[numero]);
              numero++;
            }
          }else{
            for (let j = 0; j < 4; j++) {

              fila.push(invitado[numero]);
              numero++;
            }
          }
          matriz.push(fila);
        }
      
        return matriz;
      };

      const filtrados = Invitados.filter(objeto => objeto.id_avatares);
      const totalInvitados = generarMatriz(filtrados);
      /* console.log("matrizRealizada", totalInvitados) */

      const popInfoInvitado = (invitado) => {
        Swal.fire({
          icon: 'succes',
          title: invitado.nombreCompleto,
          text: IdiomaTextActual.textaceptoinvitado + " " + invitado.username,
          confirmButtonText: 'Ok!👌'
        }).then(result => {
          if (result.isConfirmed) {
            console.log('invitado: ', invitado);
          }
       })
      }
      
    return (
      <>
      <audio ref={audioRef} src={locpLay} preload="auto"/>
      <Navbar locpLay={audioRef} imgVolumenShow={imgVolumenShow} musicaText={<a href='/#/MenuInicial'><img src={hamburg}></img></a>}/>
        <div className='fondoEnteroLoby'>
        <div className='fondo1Loby' >
            <img className='IMGFONDOLoby' src= {animacionFlyTurno ? ANIMACIONFLY : ANIMACIONFLY2} alt='Logo1'/>
            <div className="cronometroLoby" >
                <br></br>
                <span className="digitoLoby">{IdiomaTextActual.texttemporizador}:</span>
                {tiempoRestante.totalSegundos && <span className="digitoLoby"><b>{tiempoRestante.dias} {IdiomaTextActual.diacronometro} :</b></span>}
                {tiempoRestante.totalSegundos && <span className="digitoLoby"><b>{tiempoRestante.horas} {IdiomaTextActual.horascronometro} :</b></span>}
                {tiempoRestante.totalSegundos && <span className="digitoLoby"><b>{tiempoRestante.minutos} {IdiomaTextActual.mescronometro} :</b></span>}
                {tiempoRestante.totalSegundos && <span className="digitoLoby"><b>{tiempoRestante.segundos} {IdiomaTextActual.segundoscronometro} </b></span> }
                <br></br>
                <span>⌛</span>
                <br></br>
                <span className="digitoLoby">{IdiomaTextActual.texttemporizadorsegundos}: </span>
                {tiempoRestante.totalSegundos && <span className="digitoLoby"><b>{tiempoRestante.totalSegundos} {IdiomaTextActual.cronometroTextSegundos}</b></span>}
            </div>
            <div className="mensajeLoby">
                <span>{IdiomaTextActual.textlobyfinal}</span>    
                <br></br>
                <span>👇</span>
            </div>
            <br></br>
            
            <Container fluid="md" className="container">
            {totalInvitados.map((row, rowIndex) => (
              <Row className="container_Row">
                 {row.map((cell, cellIndex) => (
                <Col className="container_Row_{tiempoRestante.totalSegundos}Col"><img className="imagenAvatar" onClick={() => popInfoInvitado(cell)} src={require(`./../../imagenes/svg/${cell.id_avatares.imagen1}`)}></img></Col>
                ))}
                </Row>
            ))}
             
            </Container>
        </div>
        </div>
        </>
        );

}

export default Lobi