// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.camera {
    text-align: center;
    margin-top: 20px;
  }
  
  .video-container {
    position: relative;
  }
  
  .VideoFrame {
    width: 100%;
    /* max-width: 320px; */
    border: 2px solid black;
    border-radius: 10px;
  }
  
  .result {
    margin-top: 20px;
  }
  
 /*  canvas {
    display: block;
    margin: 20px auto;
    border: 2px solid black;
    border-radius: 10px;
  }
   */

   .contenedorCamara{
    padding: 5%;
    /* margin-top: 0.5em; */
    background-color: wheat;
    padding-bottom: 3em;
   }`, "",{"version":3,"sources":["webpack://./src/pages/FOTOSUP/Camera.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;EAClB;;CAED;;;;;;IAMG;;GAED;IACC,WAAW;IACX,uBAAuB;IACvB,uBAAuB;IACvB,mBAAmB;GACpB","sourcesContent":[".camera {\n    text-align: center;\n    margin-top: 20px;\n  }\n  \n  .video-container {\n    position: relative;\n  }\n  \n  .VideoFrame {\n    width: 100%;\n    /* max-width: 320px; */\n    border: 2px solid black;\n    border-radius: 10px;\n  }\n  \n  .result {\n    margin-top: 20px;\n  }\n  \n /*  canvas {\n    display: block;\n    margin: 20px auto;\n    border: 2px solid black;\n    border-radius: 10px;\n  }\n   */\n\n   .contenedorCamara{\n    padding: 5%;\n    /* margin-top: 0.5em; */\n    background-color: wheat;\n    padding-bottom: 3em;\n   }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
