import React, {useState, useEffect} from 'react'
import "./CargaNCasamos.css"
import TituloAnimacion from '../imagenes/noscasamos.png';
import logoAlas from '../imagenes/svg/logoAlas.svg';
import logoContenido from '../imagenes/svg/logoContenido.svg';
import BTNMUSIC from '../imagenes/svg/BTNMUSICESP.svg';
import BTNMUSICACTIVE from '../imagenes/BTNMUSICESP.png';

const CargaNCasamos = ({ quitarCarga, IdiomaTextActual, locpLay }) => {

   

    const startPlaying = () => {
        
        if (locpLay.current) {
            locpLay.current.play();
            quitarCarga(false);
        }
     };

     
     

    const [visibilidadLogo, setvisibilidadLogo] = useState(false);
    const [btnActive, setbtnActive] = useState(false);

        useEffect(() => {
            setTimeout(() => setvisibilidadLogo(true), 500);
            setTimeout(() => setbtnActive(true), 5000);
            
          }, [])

       const [tiempoRestante, setTiempoRestante] = useState({});

       useEffect(() => {
        const intervalo = setInterval(() => {
           const fechaObjetivo = new Date('2024-09-20T18:30:00');
           const tiempoActual = new Date();
           const diferencia = fechaObjetivo - tiempoActual;
            /* console.log("hora actual", tiempoActual); */
           const segundos = Math.floor(diferencia / 1000);
           const minutos = Math.floor(segundos / 60);
           const horas = Math.floor(minutos / 60);
           const dias = Math.floor(horas / 24);
       
           setTiempoRestante({
             dias,
             horas: horas % 24,
             minutos: minutos % 60,
             segundos: segundos % 60,
             totalSegundos: Math.round(diferencia / 1000),
           });
        }, 1000);
       
        return () => clearInterval(intervalo);
       }, []);


       /* useEffect(() => {
        if(localStorage.getItem("textos")){


          const importImage = async (nameImg, nameSello, nameSelloM) => {
            var module = await import("./../../imagenes/"+nameImg);
            var moduleSello = await import("./../../imagenes/svg/"+nameSello);
            var moduleSelloM = await import("./../../imagenes/svg/"+nameSelloM);
         setimagenAdrianyMajka(module.default);
         setEselloMonasterio(moduleSello.default);
         setEselloMonasterioM(moduleSelloM.default);
         };
         importImage(IdiomaTextActual.imagenLosdosText, IdiomaTextActual.imagenSello, IdiomaTextActual.imagenLosdosText);
          
  
    
        }
  
   }, []); */


    return (
        <>
        
       <footer className='CargaNCasamosDiv'>
       <img  className={visibilidadLogo ? "logoAlas rotate active" : "logoAlas rotate inactive"} src={logoAlas}></img>
            <img  className={visibilidadLogo ? "logoContenido active" : "logoContenido inactive"} src={logoContenido}></img>
        
        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        <div className="cronometro">
            <span className="digito">{IdiomaTextActual.textoCarga}</span> 
        </div>
        <br/><br/>
        {/* <div className="cronometro" >
            {tiempoRestante.totalSegundos && <span className="digito">{tiempoRestante.dias} D :</span>}
            {tiempoRestante.totalSegundos && <span className="digito">{tiempoRestante.horas} H :</span>}
            {tiempoRestante.totalSegundos && <span className="digito">{tiempoRestante.minutos} M :</span>}
            {tiempoRestante.totalSegundos && <span className="digito">{tiempoRestante.segundos} S </span> }
            
        </div> */}
        <div className="cronometro">
            <span className="digito">{IdiomaTextActual.cargamusicatext}</span> 
        </div>
        {/* <div className="cronometro">
            {tiempoRestante.totalSegundos && <span className="digito">{IdiomaTextActual.cronometroText}: {tiempoRestante.totalSegundos} {IdiomaTextActual.cronometroTextSegundos}</span>}
         
        </div> */}
        <br/>
        {btnActive ? <img  className="btnmusic" src={require(`../imagenes/${IdiomaTextActual.iconoContinuar}`)} onClick={() => startPlaying()} ></img> : <img  className="btnmusic" src={require(`../imagenes/svg/${IdiomaTextActual.iconoContinuarsvg}`)} onClick={() => startPlaying()} ></img>}
        {/*     <img  className="logoAnimacion active" src={TituloAnimacion}></img> */}
           </footer>
       </>
        
    )
}

export default CargaNCasamos;