// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iconSvg {
    width: 12%;
   }
.titulo {
    color: white;

}

.navSound {
    background-color: gold;
    border-bottom: solid 2px;
    z-index: 4;
}`, "",{"version":3,"sources":["webpack://./src/pages/Navbar/Navbar.css"],"names":[],"mappings":"AAEA;IACI,UAAU;GACX;AACH;IACI,YAAY;;AAEhB;;AAEA;IACI,sBAAsB;IACtB,wBAAwB;IACxB,UAAU;AACd","sourcesContent":["@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css');\n\n.iconSvg {\n    width: 12%;\n   }\n.titulo {\n    color: white;\n\n}\n\n.navSound {\n    background-color: gold;\n    border-bottom: solid 2px;\n    z-index: 4;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
