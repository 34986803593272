import React, { useState, useRef, useEffect  } from 'react';
import './../../App.css';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

import BOTONDERECHA from './../../imagenes/svg/BOTONDERECHA.svg';
import BOTONIZQUIERDA from './../../imagenes/svg/BOTONIZQUIERDA.svg';
import BOTONCHECK from './../../imagenes/svg/BOTONCHECK.svg';

import BOTONDERECHAON from './../../imagenes/svg/BOTONDERECHAP.svg';
import BOTONIZQUIERDAON from './../../imagenes/svg/BOTONIZQUIERDAP.svg';
import BOTONCHECKON from './../../imagenes/svg/BOTONCHECKP.svg';

function Carrusel(props) {

  const divRef = useRef(null);


  useEffect(() => {

    
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            Swal.fire({
              title: props.IdiomaTextActual.titlealertseleccion,
              text: props.IdiomaTextActual.mensajealertseleccion,
              icon: 'info',
              confirmButtonText: props.IdiomaTextActual.botonalertseleccion
            });
            // Dejar de observar una vez que la alerta ha sido mostrada
            observer.unobserve(entry.target);
          }
        },
        { threshold: 0.05 } // El 5% del div debe ser visible para activar la alerta
      );
  
      if (divRef.current) {
        observer.observe(divRef.current);
      }
  
      // Limpieza del observer cuando el componente se desmonta
      return () => {
        if (divRef.current) {
          observer.unobserve(divRef.current);
        }
      };

    
  }, []);

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [selectionOn, setselectionOn] = useState(true);

    const [selectionBC, setselectionBC] = useState(false);
    const [selectionBI, setselectionBI] = useState(false);
    const [selectionBD, setselectionBD] = useState(false);
     
    function handleNextImage() {
            
        
        if(selectionOn){
            setCurrentImageIndex((currentImageIndex + 1) % props.imagenes.length);
            setselectionBD(true);
            setTimeout(() => setselectionBD(false), 200);
        }

       }
       
       function handlePreviousImage() {
       
        if(selectionOn){
            setCurrentImageIndex((currentImageIndex - 1 + props.imagenes.length) % props.imagenes.length);
            setselectionBI(true);
            setTimeout(() => setselectionBI(false), 200);

        }

       }

       //seleccion boton

       function handleSelectoImage() {
        Swal.fire({
            title: props.IdiomaTextActual.titlealertavatar,
            html:
            '<div Class="FONDOSWALAVATAR" >' +
              '<input type="hidden" id="terms" value="666555666" placeHolder="MOVIL">' +
              /* '<label for="terms">condicion uno</label><br>' + */
              '<input type="hidden" id="terms2" value="correo@gmail.com" placeHolder="CORREO">' +
              /* '<label for="terms">condicion dos</label><br>' + */
              /* '<footer> ' +
              '<div class="footer-img">' + */
              '<img src="' + require(`./../../imagenes/${props.imagenes[currentImageIndex].imagen2}`) + '" style="width: 100%;" alt="Imagen del footer">' +
              /* '</div>' +
              '</footer>', */
              '</div>',
              
            confirmButtonText: props.IdiomaTextActual.botonalertavatar,
            focusConfirm: false,
            preConfirm: () => {
              return {
                terms: document.getElementById('terms'),
                terms2: document.getElementById('terms2'),
              }
            }
         }).then(result => {
            if (result.isConfirmed) {
              if (result.value.terms.value.length > 0 && result.value.terms2.value.length > 0 ) {
                /* Swal.fire('tiene datos') */
                
                setselectionOn(false);
                setselectionBC(true);
                props.setSelectAvatar(false);
                props.focusForm.focus();
                props.setidavatar(props.imagenes[currentImageIndex]._id);
                
              } else {
                Swal.fire('faltan datos')
              }
            }
         });

       }
       
       function handleNavigationButtonClick(e) {
        
        if (e.target.name === 'next') {
            handleNextImage();
         } else if (e.target.name === 'previous') {
            handlePreviousImage();
        } else if (e.target.name === 'seleccion') {
            handleSelectoImage();
         }

       }
       /* imagenes ? console.log("respuesta en carrousel:", imagenes[1].imagen1) : console.log("sin respuesta en carrousel:", imagenes[0]);
     */
       
       


       return (
        <div className= {selectionOn ? "IMGFONDOSELEC" : "IMGFONDOSELECon"} ref={divRef} >
          {/* <div>
          <h5>Selecciona un avatar entre los siguientes:</h5><br></br>
          <h7>hola</h7><br></br>
          <h7>hola</h7>
          </div> */}
            <img src={selectionOn ? require(`./../../imagenes/svg/${props.imagenes[currentImageIndex].imagen1}`) : require(`./../../imagenes/${props.imagenes[currentImageIndex].imagen2}`) } className='IMGFONDOCARROUSEL' alt="Imagen del carrusel" />
               <div className="BotonesDiv">
                  <img src={selectionBI ? BOTONIZQUIERDAON : BOTONIZQUIERDA} alt="Imagen del CONTROL" name="previous" className='BotonCarrusel' onClick={handleNavigationButtonClick} />
                  <img src={selectionBC ? BOTONCHECKON : BOTONCHECK} alt="Imagen del CONTROL" name="seleccion" className='BotonCarrusel' onClick={handleNavigationButtonClick} />
                  <img src={selectionBD ? BOTONDERECHAON : BOTONDERECHA} alt="Imagen del CONTROL" name="next" className='BotonCarrusel' onClick={handleNavigationButtonClick} />
            
                   {/* <button name="previous" className='BotonCarrusel' onClick={handleNavigationButtonClick}>
                       Anterior
                   </button>
                   <button name="seleccion" className='BotonCarrusel' onClick={handleNavigationButtonClick}>
                       Seleccionar
                   </button>
                   <button name="next" className='BotonCarrusel' onClick={handleNavigationButtonClick}>
                       Siguiente
                   </button> */}
               </div>
        </div>
       );

   }

   export default Carrusel;