import React from 'react';
import {QRCode} from 'react-qrcode-logo';

const QRCodeWithLogo = ({ url }) => {
    console.log("url del QR: ", url);
  return <QRCode
        value={url}
        ecLevel="M"
        quietZone={10}
        logoImage="https://file.adrianymarianna.com/logoContenido512.png"
        //logoImage="logoContenido512.png"  
        size="300"
        bgColor="#ffffff"
        fgColor="#e25d16"
        logoWidth={90} // Ancho del logo en píxeles
        logoHeight={90} // Altura del logo en píxeles
        /* logoOpacity={1} */
        logoPaddingStyle="dots"
        removeQrCodeBehindLogo={true}
        qrStyle="dots"
      />
};

export default QRCodeWithLogo;
