import React,  { useState, useEffect } from 'react';
/* import ShakeDetector from './ShakeDetector'; */
import imgVolumenOn from './../../imagenes/svg/VOLUMENON.svg';
import imgVolumenOff from './../../imagenes/svg/VOLUMENOFF.svg';
import imgVolumenOnP from './../../imagenes/svg/VOLUMENONP.svg';
import imgVolumenOffP from './../../imagenes/svg/VOLUMENOFFP.svg';

import './Navbar.css';

function Navbar(locpLay) {
    const [audioVolumenShow, setaudioVolumenShow] = useState(locpLay.imgVolumenShow);

  
    

 const startPlaying = () => {
  console.log("activa sonid:", locpLay.locpLay.current);
    if (locpLay.locpLay.current) {
      locpLay.locpLay.current.play();
      setaudioVolumenShow(false);
    }
 };

 const stopPlaying = () => {
    if (locpLay.locpLay.current) {
      locpLay.locpLay.current.pause();
      setaudioVolumenShow(true);
    }
 };
 
 

 return (
    <nav className="navbar navbar-expand-lg navSound">
      <img src={audioVolumenShow ? imgVolumenOn : imgVolumenOnP} onClick={startPlaying} className="navbar-brand iconSvg" href="#" />
      {/* <ShakeDetector/> */}
      <span className="ms-3 titulo"><h4>{locpLay.musicaText}</h4></span>
      <img className="navbar-brand iconSvg" src={audioVolumenShow ? imgVolumenOffP : imgVolumenOff} onClick={stopPlaying}   href="#" />
      {/* <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav">
          <li className="nav-item active">
            <a className="nav-link" href="#">Home</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">Features</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">Pricing</a>
          </li>
        </ul> 
      </div> */}
    </nav>
 );
}

export default Navbar;