import React, { useState, useRef } from 'react';
import { useParams, useNavigate } from "react-router-dom";

import Swal from 'sweetalert2'

import NAVINSIDEOUTCLOSE from '../imagenes/svg/NAVINSIDEOUTCLOSE.svg';
import NAVINSIDEOUTOPEN from '../imagenes/svg/fondoMenuInicial.svg';
import BUTTON_INSIDE from '../imagenes/svg/BUTTON_INSIDE.svg';

import Navbar from './../pages/Navbar/Navbar';
import audiomp3 from './../audio/outsideintro.mp3';


import hamburg from './../imagenes/bar-de-hamburguesas.png';

import "./NavInicial.css"

const VideoBackground = ({ quitarCarga, IdiomaTextActual, IndexSlide}) => {
  const audioRef = useRef(null);
  const [locpLay, setlocpLay] = useState(audiomp3);
  const [imgVolumenShow, setimgVolumenShow] = useState(true);


  const navigate = useNavigate();

  const [idinvitado, seidinvitado] = useState(JSON.parse(localStorage.getItem("invitado"))._id);



  console.log("idiomas", IdiomaTextActual);
  const [textCarrusel, settextCarrusel] = useState("estandar"); 
  setTimeout(() => actutextoCar(), 50);
  const actutextoCar = () => {
  switch (IndexSlide) {
    case 0:
      settextCarrusel("   Información   ");
      break; 
    case 1:
      settextCarrusel("     Hotel     ");
      break;
    case 2:
      settextCarrusel("     Autobús     ");
      break;
    case 3:
      settextCarrusel("Menú del catering");
      break;
    case 4:
      settextCarrusel("     Fotos     ");
      break; 
    case 5:
      settextCarrusel("    Música     ");
      break;
    case 6:
      settextCarrusel("Despedida Novio");
      break;
    case 7:
      settextCarrusel("Despedida Novia");
      break;
    case 8:
      settextCarrusel("   Registro   ");
      break;
    case 9:
      settextCarrusel("   Invitados   ");
      break;  
            
  }
}

  const esperaInvitacion = () => {
    Swal.fire({
      icon: 'error',
      title: '🥲',
      text: IdiomaTextActual.swalnoregistro,
      confirmButtonText: '<i class="fas fa-check"></i> Aceptar',
            confirmButtonColor: '#2ecc71',
            confirmButtonAriaLabel: 'OK'
    }).then(result => {
      if (result.isConfirmed) {
        window.location.replace('https://www.google.com/search?q=GNET+-+Grupalia+Internet+S.A.');
      }
   })



  };
  const continuaInvitacion = () => {
    /* alert(IndexSlide); */
    switch (IndexSlide) {
      case 0:
        navigate("/InfoUtil");
        break;
      case 1:
        navigate("/Hotel");
        break;
      case 2:
        navigate("/planwed");
        break;
      case 3:
        navigate("/menu/"+idinvitado);
        break;
      case 4:
        navigate("/fotos/");
        break;
      case 5:
        navigate("/spoty/");
        break;
      case 6:
        navigate("/cardNovio/");
        break;
      case 7:
        navigate("/cardNovia/");
        break;
      case 8:
        navigate("/registroDirecto/");
        break;
      case 9:
        navigate("/loby/");
        break;          
    }
      /* quitarCarga(false); */

  };

  return (
    <>
    
    <footer className='NavInicial' /* onClick={continuaInvitacion} */>
    <audio ref={audioRef} src={locpLay} preload="auto"/>
    <Navbar className="navmusic" locpLay={audioRef} imgVolumenShow={imgVolumenShow} musicaText={textCarrusel}/>  
        {/* <pre className="textoNav"></pre> */}
        <img className='NavOpen_1' src={NAVINSIDEOUTOPEN}  onClick={continuaInvitacion}></img>
        <img className='NavOpen_2' src={BUTTON_INSIDE} onClick={continuaInvitacion}></img>
    </footer>
    </>
  );
};

export default VideoBackground;
