import React, {useRef, useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import './Home.css';
import { useParams, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import CargaNCasamos from "./../../carga/cargaNCasamos"
import Navbar from './../../pages/Navbar/Navbar';

import { API } from "../../services/api";

//IMAGENES
import image from "./../../imagenes/noscasamos.png";
import imageInf from "./../../imagenes/InferiorSunFlowers.png";
import selloMonasterio from "./../../imagenes/svg/selloMonasterio.svg";
import adrianymajka from "./../../imagenes/adrianymajkaSUN.png";
import foto2nuestra from "./../../imagenes/fotolos2GIF.png";
import cartaSobre from "./../../imagenes/cartaSobre.png";
import sellocartaLogo from "./../../imagenes/svg/sellocartaLogo.svg";
import sellocartaLogoON from "./../../imagenes/svg/sellocartaLogoON.svg";
import audiomp3 from './../../audio/Marry_You_Bruno_Mars_Naijapals.mp3';

import hamburg from './../../imagenes/bar-de-hamburguesas.png';

const Home = () => {
  const navigate = useNavigate();
  const { id_inv } = useParams();
  const audioRef = useRef(null);

/* console.log(id_inv); */

  const [locpLay, setlocpLay] = useState(audiomp3);
    
  const [imgVolumenShow, setimgVolumenShow] = useState(false);

  const [cargaNCasamosShow, setcargaNCasamosShow] = useState(true);




  const cargaNCasamosON = (valor) =>{
      setcargaNCasamosShow(valor);
  } 

  

//GENERO VARIABLES CON LA INFO

const [UserId, setUserId] = useState({});
const [IdiomaText, setIdiomaText] = useState([]);
const [imagenAdrianyMajka, setimagenAdrianyMajka] = useState(adrianymajka);
const [EselloMonasterio, setEselloMonasterio] = useState(sellocartaLogo);
const [EselloMonasterioM, setEselloMonasterioM] = useState(sellocartaLogoON);


const [IdiomaTextActual, setIdiomaTextActual] = useState({
  "_id": "6599cac40db07abe9ffb9e96",
			"idioma": "espana",
			"cargamusicatext": "¡Sube el volumen!",
			"iconoContinuar": "BTNMUSICESP.png",
			"iconoContinuarsvg": "BTNMUSICESP.svg",
			"imgNoscasamos": "noscasamos.png",
			"titleswalsininv": "No se quién eres\n\n😷\n",
			"textswalsininv": "Debes acceder por el enlace que te hemos enviado. Lo siento.",
			"buttonswalsininv": "¡OK!😒",
			"titleswalfirma": "Necesitamos una firmita por aqui…",
			"buttonswalfirmacancel": "Cerrar",
			"buttonfirmalimpiar": "Limpiar",
			"buttonfirmaguardar": "Guardar",
			"textformlargo": "Todavía estamos ultimando algunos detalles de la boda. Por ello, necesitamos que nos confirmes tus datos de contacto, para poder avisarte de nuevas noticias sobre el evento.",
			"textoinput1": "Móvil:",
			"textoinput2": "Email:",
			"inputtextarea": "¿Se te ocurre algún otro avatar? ¡Dinos cuál! Recuerda que la temática es: flores, plantas…",
			"buttonconfirmacion": "Confirmar asistencia",
			"textosCarta": "Queridos amigos, ¡la hora ha llegado!\nAdrián y Majka, por fin casados.\nSerá en Lupiana, el 20 de septiembre,\nfirmando que su amor dure para siempre.\n\nNo falten a este día tan especial,\ncon baile, comida, ¡será genial!\nOs esperamos con cariño y emoción,\npara ser testigos de esta unión.",
			"textosCheckCarta": "Acepto las condiciones de la invitación",
			"textoCheck1": "Acepto bailar como si nadie estuviera mirando, incluso si todos lo están haciendo, aunque mis movimientos se asemejen más a un pingüino en patinaje sobre hielo",
			"textoCheck2": "Estoy de acuerdo en reírme hasta que me duela la barriga, aunque mi risa suene como una mezcla entre una hiena y un pato",
			"textoCheck3": "Acepto que las fotos embarazosas son una parte obligatoria de la celebración, y me uniré a la diversión sin quejarme, incluso si eso implica usar accesorios ridículos para fotos grupales",
			"textoCheck4": "Prometo no contar chistes malos, a menos que sean tan malos que se vuelvan buenos por accidente",
			"textoCheck5": "Acepto que la pista de baile será mi gimnasio y bailaré hasta quemar más calorías de las consumidas en la cena",
			"textoCheck6": "Acepto tomarme un chupito de Soplica para no hacerle un feo a la novia",
			"textoCheck7": "Estoy dispuesto a aplaudir, gritar \"vivan los novios\" y vitorear exageradamente, animando a los novios y demás invitados como si estuviera en un estadio deportivo",
			"textoCheck8": "Estoy de acuerdo en que los abrazos y besos son obligatorios, y cualquier intento de resistirse será castigado con abrazos y besos aún más fuertes",
			"textoCheck9": "Prometo no rechazar ninguna oferta de comida, postre o delicioso manjar que se cruce en mi camino durante la celebración",
			"textoCheck10": "Acepto que las risas y los recuerdos compartidos superarán cualquier intento de mantener la dignidad intacta",
			"textoCheck11": "Me comprometo a confirmar mi asistencia en fecha. Si no, me llevaré una silla y un sándwich",
			"textoFailCheckTitle": "Acepta, TODAS las condiciones.",
			"textoFailCheck": "Aqui, hemos venido a jugar.",
			"textoSucesCheckTitle": "Gracias por aceptar las condiciones",
			"textoSucesCheck": "Ahora solo queda que elijas tu avatar.",
			"createdAt": "2024-01-06T21:48:52.020Z",
			"updatedAt": "2024-01-21T22:25:53.666Z",
			"__v": 0,
			"cronometroText": "Faltan",
			"imagenLosdosText": "adrianymajkaSUN.png",
			"cronometroTextSegundos": "Seg.",
			"musicaText": "Activa el sonido",
			"textoTyC": "Condiciones de asistencia",
			"textoCarga": "Tenemos algo importante que deciros...\n¡Nos casamos!\n",
			"imagenSello": "sellocartaLogo.svg",
			"imagenSelloM": "sellocartaLogoON.svg"
});

  useEffect(() => {
    const getUserId = async () => {
      const userInv =  await API.get("/invitados/"+ id_inv).then((res) => {
        console.log( "respuesta inv ",res.data.invitados);
        setUserId(res.data.invitados);
        
        localStorage.setItem("invitado", JSON.stringify(res.data.invitados));

      })
    };
    getUserId();
  }, []);

  useEffect(() => {
    const getUserIdioma = async () => {
      
        const IdiomasText = await API.get("/textos/").then((res) => {
          
            localStorage.setItem("textos", JSON.stringify(res.data.Textos)); 
            
          console.log("idiomas", res.data.Textos);
        })
      };
   getUserIdioma();
  }, []);



    

  







    /* console.log("variable: ", UserId.nombreCompleto); */
/* console.log("despuies:", UserId.invitados.nombreCompleto); */
//tiempo para quitar carga
  /* useEffect(() => {
    setTimeout(() => setExecute(true), 5000);
    
  }, []) */







  const [firmaOn, setfirmaOn] = useState(false);//estado inicial false

const [text, setText] = useState('');

const [currentIndex, setCurrentIndex] = useState(0);

  
 /*  'Querido amigo, con emoción sincera, \nTe invitamos a un día de alegría y amor, \nEn el Monasterio de San Bernardo, un clamor, \nDonde el alma y el corazón se quieren entrelazar. \nEn 22 de septiembre, la historia de dos almas se unirá, \nEl reloj marcará la hora de unir destinos, \nEn un lugar de historia y rincones divinos, \nDonde el amor eterno habremos de jurar. \nLas campanas sonarán, el viento susurrará, \nEn este rincón sagrado, prometemos amarnos, \nCon la naturaleza y la historia como testigos, \nY queremos que tú, querido amigo, estés allí. \nConfirma tu asistencia, por favor, sin tardar, \nUn día de alegría, juntos vamos a celebrar.';
  */
  useEffect(() => {

    const timer = setTimeout(() => {
        setText(IdiomaTextActual.textosCarta.slice(0, currentIndex + 1));

      setCurrentIndex(currentIndex + 1);

    }, 50);

    return () => clearTimeout(timer);

 }, [currentIndex, IdiomaTextActual.textosCarta]);

 

//REPETIR TEXTO ESCRITO MAQUINA
 useEffect(() => {
      if (currentIndex === IdiomaTextActual.textosCarta.length) {


        setfirmaOn(true);
        /* setCurrentIndex(0); */
  
      }
      
      
    
    

 }, [currentIndex, IdiomaTextActual.textosCarta, setfirmaOn]);
  

 //funcion click carta

 const [selloOn, setselloOn] = useState(true);//estado inicial true

 const [cartaOn, setcartaOn] = useState(true);//estado inicial true



 const abrirSobre = (idselect) => {

    //RELLENAR DATOS SESION

    fetch('https://api.ipify.org?format=json')
    .then(response => response.json())
    .then(data => {
       console.log('IP:', data.ip);
       /* alert(data.ip); */
       const sesionUp = API.post("/sesion", {"ip": data.ip, "id_invitado": id_inv}).then((res) => {
         /* console.log( "respuesta",res); */
         /* alert(res); */
         
       })
   
    });
    //FIN SESION

  setselloOn(idselect);
  setfirmaOn(false);
  setCurrentIndex(0)
  setTimeout(() => setcartaOn(idselect), 500)
  /* setTimeout(() => setCurrentIndex(0), 1000) */
  

};
 //fin click carta

 //duncion swal con condiciones

 const  activarCondiciones = (valorClick) => {
  
  Swal.fire({
    title: IdiomaTextActual.textoTyC,
    html:
      '<div class="condicionesT">'+
      '<div class="condicionT">'+
      '<input class="inputT" type="checkbox" id="terms">' +
      '<label class="labelT" for="terms">'+ IdiomaTextActual.textoCheck1 +'</label><br>' +
      '</div>'+
      '<div class="condicionT">'+
      '<input class="inputT" type="checkbox" id="terms2">' +
      '<label class="labelT" for="terms">'+ IdiomaTextActual.textoCheck2 +'</label><br>'+
      '</div>'+
      '<div class="condicionT">'+
      '<input class="inputT" type="checkbox" id="terms3">' +
      '<label class="labelT" for="terms">'+ IdiomaTextActual.textoCheck3 +'</label><br>'+
      '</div>'+
      '<div class="condicionT">'+
      '<input class="inputT" type="checkbox" id="terms4">' +
      '<label class="labelT" for="terms">'+ IdiomaTextActual.textoCheck4 +'</label><br>'+
      '</div>'+
      '<div class="condicionT">'+
      '<input class="inputT" type="checkbox" id="terms5">' +
      '<label class="labelT" for="terms">'+ IdiomaTextActual.textoCheck5 +'</label><br>'+
      '</div>'+
      '<div class="condicionT">'+
      '<input class="inputT" type="checkbox" id="terms6">' +
      '<label class="labelT" for="terms">'+ IdiomaTextActual.textoCheck6 +'</label><br>'+
      '</div>'+
      '<div class="condicionT">'+
      '<input class="inputT" type="checkbox" id="terms7">' +
      '<label class="labelT" for="terms">'+ IdiomaTextActual.textoCheck7 +'</label><br>'+
      '</div>'+
      '<div class="condicionT">'+
      '<input class="inputT" type="checkbox" id="terms8">' +
      '<label class="labelT" for="terms">'+ IdiomaTextActual.textoCheck8 +'</label><br>'+
      '</div>'+
      '<div class="condicionT">'+
      '<input class="inputT" type="checkbox" id="terms9">' +
      '<label class="labelT" for="terms">'+ IdiomaTextActual.textoCheck9 +'</label><br>'+
      '</div>'+
      '<div class="condicionT">'+
      '<input class="inputT" type="checkbox" id="terms10">' +
      '<label class="labelT" for="terms">'+ IdiomaTextActual.textoCheck10 +'</label><br>'+
      '</div>'+
      '<div class="condicionT">'+
      '<input class="inputT" type="checkbox" id="terms11">' +
      '<label class="labelT" for="terms">'+ IdiomaTextActual.textoCheck11 +'</label><br>'+
      '</div>'+
      '</div>',
    confirmButtonText: IdiomaTextActual.botonalertseleccion,
    focusConfirm: false,
    preConfirm: () => {
      return {
        terms: document.getElementById('terms').checked,
        terms2: document.getElementById('terms2').checked,
        terms3: document.getElementById('terms3').checked,
        terms4: document.getElementById('terms4').checked,
        terms5: document.getElementById('terms5').checked,
        terms6: document.getElementById('terms6').checked,
        terms7: document.getElementById('terms7').checked,
        terms8: document.getElementById('terms8').checked,
        terms9: document.getElementById('terms9').checked,
        terms10: document.getElementById('terms10').checked,
        terms11: document.getElementById('terms11').checked,
      }
    }
 }).then(result => {
    if (result.isConfirmed) {
      if(id_inv != "669f5c3a38ea539a5ed75db5"){
        if (result.value.terms && result.value.terms2 && result.value.terms3 && result.value.terms4 && result.value.terms5 && result.value.terms6 && result.value.terms7 && result.value.terms8 && result.value.terms9 && result.value.terms10 && result.value.terms11) {
          /* Swal.fire({
            icon: 'success',
            title: 'Gracias por aceptar las condiciones',
            text: 'Ahora solo queda que elijas tu avatar.',
            confirmButtonText: '<i class="fas fa-check"></i> Aceptar',
                  confirmButtonColor: '#2ecc71',
                  confirmButtonAriaLabel: 'Aceptar'
          }) */
          
          navigate("/registro");
  
        } else {
          Swal.fire({
            icon: 'error',
            title: IdiomaTextActual.textoFailCheckTitle,
            text: IdiomaTextActual.textoFailCheck
          })
          setTimeout(() => activarCondiciones(true), 2500);
        }
      }else{
        navigate("/registro");
      }
      
    }
 });
  
 };
  //fin click condiciones
    //intento cambiar idioma
    /* console.log( "respuesta filt1", localStorage.getItem("textos"));
    console.log( "respuesta filt", JSON.parse(localStorage.getItem("invitado")).nombreCompleto); */

    useEffect(() => {
      if(localStorage.getItem("textos")){

        const filtrarPorIdioma = (elemento) => elemento.idioma === JSON.parse(localStorage.getItem("invitado")).idioma;
        const arrayTextos = JSON.parse(localStorage.getItem('textos'));
        const resultado = arrayTextos.filter(filtrarPorIdioma);

        setIdiomaTextActual(resultado[0]);
        
        const importImage = async (nameImg, nameSello, nameSelloM) => {
          var module = await import("./../../imagenes/"+nameImg);
          var moduleSello = await import("./../../imagenes/svg/"+nameSello);
          var moduleSelloM = await import("./../../imagenes/svg/"+nameSelloM);
       setimagenAdrianyMajka(module.default);
       setEselloMonasterio(moduleSello.default);
       setEselloMonasterioM(moduleSelloM.default);
       };
       importImage(resultado[0].imagenLosdosText, resultado[0].imagenSello, resultado[0].imagenSelloM);
        

  
      }

 }, []);
    
    return (
      <>
      <audio ref={audioRef} src={locpLay} preload="auto"/>
      <Navbar locpLay={audioRef} imgVolumenShow={imgVolumenShow} musicaText={<a href='/#/MenuInicial'><img src={hamburg}></img></a>}/>
      <div className='HomePage' >
      <div className={cartaOn ? 'HomeEntrada Fijado' : 'HomeEntrada Fijado inactive2'} >
          <img className='nombresEntrada' src= {imagenAdrianyMajka} alt='LogoNombres'/>  
          <img className='nombresEntrada FOTOSLOS2' src= {foto2nuestra} alt='LogoNombres'/>
          <div className='SobreCarta'>
            {selloOn ? <img className='selloCartaLogo' onClick={() => abrirSobre(false)} src= {EselloMonasterio} alt="selloCarta"/> :  <img className='selloCartaLogo' onClick={() => abrirSobre(true)} src= {EselloMonasterioM} alt='LogoON'/>}

          </div>
          {/* <img className='SobreCarta' src= {cartaSobre} alt='LogoNombres'/>  */} 
      </div>
      <div className={cartaOn ? 'HomeCarta inactive2' : 'HomeCarta'}>
          <h7 className='tituloInvitado'><b>{UserId.nombreCompleto}</b>,</h7>
        { text ?  
          <p className='letrasCarta' id="TextoInvitado">{text}</p>
        : "Sin nada" }
            <form className={firmaOn ? 'formularioInv' : 'formularioInv inactive2'}>
              <div className='checkInv'>
                <input type="checkbox" onClick={() => activarCondiciones(true)} className="inputCheck" required></input><br/><label><b> {IdiomaTextActual.textosCheckCarta}</b></label>
              </div>
              <img className='InferiorSello' src={selloMonasterio} alt='Logo1'/>
            </form>
        </div>
       
        <div className='navBarInferior'> 
        </div>
      </div>
      {cargaNCasamosShow && <CargaNCasamos IdiomaTextActual={IdiomaTextActual} quitarCarga={(cargaNCasamosON)} locpLay={audioRef}></CargaNCasamos>}
    </>
    );
  }

export default Home