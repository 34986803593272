import React, {useRef, useState, useEffect} from 'react'
import QRCodeWithLogo from './Qrcode.jsx';
import { API } from "../../services/api";


import "./QRindex.css";

import { useParams, useNavigate } from "react-router-dom";

const QRindex = () => {
    const { id_inv } = useParams();
	console.log(id_inv)
	/* if(id_inv != "undefined"){ */
		const url = "https://nuestraboda.adrianymarianna.com/#/inv/"+id_inv;
	/* }else{
		const url = "https://nuestraboda.adrianymarianna.com/#/inv/"+JSON.parse(localStorage.getItem("invitado"))._id;
	} */
   


    const [IdiomaTextActual, setIdiomaTextActual] = useState({
			"_id": "6599cac40db07abe9ffb9e96",
			"idioma": "espana",
			"imgNoscasamos": "noscasamos.png",
			"textosCarta": "Queridos amigos, ¡la hora ha llegado!\nAdrián y Majka, por fin casados.\nSerá en Lupiana, el 20 de septiembre,\nfirmando que su amor dure para siempre.\n\nNo falten a este día tan especial,\ncon baile, comida, ¡será genial!\nOs esperamos con cariño y emoción,\npara ser testigos de esta unión.",
			"textosCheckCarta": "Pasar a aceptar las condiciones de asistencia",
			"textoCheck1": "Acepto bailar como si nadie estuviera mirando, incluso si todos lo están haciendo, aunque mis movimientos se asemejen más a un pingüino en patinaje sobre hielo",
			"textoCheck2": "Estoy de acuerdo en reírme hasta que me duela la barriga, aunque mi risa suene como una mezcla entre una hiena y un pato",
			"textoCheck3": "Acepto que las fotos embarazosas son una parte obligatoria de la celebración, y me uniré a la diversión sin quejarme, incluso si eso implica usar accesorios ridículos para fotos grupales",
			"textoCheck4": "Prometo no contar chistes malos, a menos que sean tan malos que se vuelvan buenos por accidente",
			"textoCheck5": "Acepto que la pista de baile será mi gimnasio y bailaré hasta quemar más calorías de las consumidas en la cena",
			"textoCheck6": "Acepto tomarme un chupito de Soplica para no hacerle un feo a la novia",
			"textoCheck7": "Estoy dispuesto a aplaudir, gritar \"vivan los novios\" y vitorear exageradamente, animando a los novios y demás invitados como si estuviera en un estadio deportivo",
			"textoCheck8": "Estoy de acuerdo en que los abrazos y besos son obligatorios, y cualquier intento de resistirse será castigado con abrazos y besos aún más fuertes",
			"textoCheck9": "Prometo no rechazar ninguna oferta de comida, postre o delicioso manjar que se cruce en mi camino durante la celebración",
			"textoCheck10": "Acepto que las risas y los recuerdos compartidos superarán cualquier intento de mantener la dignidad intacta",
			"textoCheck11": "Me comprometo a confirmar mi asistencia en fecha. Si no, me llevaré una silla y un sándwich",
			"textoFailCheckTitle": "Acepta, TODAS las condiciones.",
			"textoFailCheck": "Aqui, hemos venido a jugar.",
			"textoSucesCheckTitle": "Gracias por aceptar las condiciones",
			"textoSucesCheck": "Ahora solo queda que elijas tu avatar.",
			"createdAt": "2024-01-06T21:48:52.020Z",
			"updatedAt": "2024-05-06T18:50:05.895Z",
			"__v": 0,
			"cronometroText": "Faltan",
			"imagenLosdosText": "adrianymajkaSUN.png",
			"cronometroTextSegundos": "Segundos",
			"musicaText": "Activa el sonido",
			"textoTyC": "Condiciones de asistencia",
			"textoCarga": "Tenemos algo importante que deciros...\n¡Nos casamos!\n",
			"imagenSello": "sellocartaLogo.svg",
			"imagenSelloM": "sellocartaLogoON.svg",
			"buttonfirmaguardar": "Guardar",
			"buttonfirmalimpiar": "Limpiar",
			"buttonswalfirmacancel": "Cerrar",
			"cargamusicatext": "¡Sube el volumen!",
			"iconoContinuar": "BTNMUSICESP.png",
			"iconoContinuarsvg": "BTNMUSICESP.svg",
			"inputtextarea": "¿Se te ocurre algún otro avatar? ¡Dinos cuál! Recuerda que la temática es: flores, plantas…",
			"textformlargo": "Todavía estamos ultimando algunos detalles de la boda. Por ello, necesitamos que nos confirmes tus datos de contacto, para poder avisarte de nuevas noticias sobre el evento.",
			"textoinput1": "Móvil:",
			"textoinput2": "Email:",
			"textswalsininv": "Debes acceder por el enlace que te hemos enviado. Lo siento.",
			"titleswalfirma": "Necesitamos una firmita por aqui…",
			"titleswalsininv": "No se quién eres\n\n😷\n",
			"buttonconfirmacion": "Confirmar asistencia",
			"texttemporizador": "¿Cuánto falta para el gran día?",
			"texttemporizadorsegundos": "Tan solo faltan",
			"textlobyfinal": "Os iremos avisando tan pronto tengamos todos los detalles del evento. Mientras tanto, por favor, esperad aquí junto al resto de invitados.",
			"swalnoregistro": "Esperamos que puedas venir",
			"textoMorfeo": "Tomas la píldora azul... la historia termina, te despiertas en tu cama y crees lo que quieras creer. Tomas la píldora roja... te quedas en el País de las Maravillas, y te enseño lo profunda que es la madriguera del conejo",
			"videoRick": "rickadmortyvideo.mp4",
			"qrtext1": "Lo sentimos, usa el movil.",
			"qrtext2": "n buen amigo me dijo una vez que el 90% de los usuarios que visualizan una web lo hace desde un terminal movil. Por eso he decidido hacer la aplicacion solo para moviles. Escanea el siguiente QR para acceder.",
			"qrtext3": "PD: Las fotos de la boda si seran accesible por ordenador."
		});

    useEffect(() => {
      const getUserIdioma = async () => {
        
          const IdiomasText = await API.get("/textos/").then((res) => {
            
              localStorage.setItem("textos", JSON.stringify(res.data.Textos)); 
              
            console.log("idiomas", res.data.Textos);
          })
        };
     getUserIdioma();
    }, []);

    useEffect(() => {
      if(localStorage.getItem("textos")){

        const filtrarPorIdioma = (elemento) => elemento.idioma === JSON.parse(localStorage.getItem("invitado")).idioma;
        const arrayTextos = JSON.parse(localStorage.getItem('textos'));
        const resultado = arrayTextos.filter(filtrarPorIdioma);

        setIdiomaTextActual(resultado[0]);
       };
 }, []);
    
    
  return (
    <>
    <div className="qrcodediv">
        <h1>{IdiomaTextActual.qrtext1}</h1>
        <div className="texto">
        <p>{IdiomaTextActual.qrtext2}</p>
        </div>
        <div className="texto">
            <p>{IdiomaTextActual.qrtext3}</p>
        </div>
        {id_inv ? <QRCodeWithLogo url={"https://nuestraboda.adrianymarianna.com/#/inv/"+id_inv} /> : <QRCodeWithLogo url={"https://nuestraboda.adrianymarianna.com/#/inv/"+JSON.parse(localStorage.getItem("invitado"))._id} />}
        {/* <QRCodeWithLogo url={url} /> */}
    </div>
    
    </>
  )
    
};

export default QRindex;