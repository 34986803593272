import React, { useState, useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import { API } from "../../services/api";
import './panelUser.css';
import { useParams, useNavigate } from "react-router-dom";
import Navbar from './../../pages/Navbar/Navbar';
import audiomp3 from './../../audio/sonidonaturaleza.mp3';
import hamburg from './../../imagenes/bar-de-hamburguesas.png';
const PanelUser = () => {
  const [invitados, setInvitados] = useState([]);
  const [idiomaTextActual, setIdiomaTextActual] = useState({});
  const [contador, setcontador] = useState(0);
  
  const [locpLay, setlocpLay] = useState(audiomp3);
  const [imgVolumenShow, setimgVolumenShow] = useState(true);
  const audioRef = useRef(null);

  const { id_inv } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      if (true)/* (localStorage.getItem("invitado")) */ {
        const getInvitados = async () => {
          try {
            if(contador == 0){
              const res = await API.get("/invitados");
            console.log("respuesta", res);

            const arrayInvitados = res.data.invitados;
            const filtrarPorId = (elemento) => elemento._id === id_inv;
            const resultadoID = arrayInvitados.filter(filtrarPorId);
            console.log("despues del filtro", resultadoID);
            setInvitados(resultadoID[0]);
            localStorage.setItem("invitado", JSON.stringify(resultadoID[0]));
            setcontador(1);
            }
          } catch (error) {
            console.error("Error fetching invitados", error);
          }
        };

        if (localStorage.getItem("textos")) {
          const filtrarPorIdioma = (elemento) => elemento.idioma === JSON.parse(localStorage.getItem("invitado")).idioma;
          const arrayTextos = JSON.parse(localStorage.getItem('textos'));
          const resultado = arrayTextos.filter(filtrarPorIdioma);
          setIdiomaTextActual(resultado[0]);
        }

        getInvitados();
      } else {
        Swal.fire({
          icon: 'error',
          title: idiomaTextActual.titleswalsininv,
          text: idiomaTextActual.textswalsininv,
          confirmButtonText: idiomaTextActual.buttonswalsininv
        }).then(result => {
          if (result.isConfirmed) {
            window.location.replace('https://www.google.com/search?q=GNET+-+Grupalia+Internet+S.A.');
          }
        });
      }
    };

    fetchData();
  }, [idiomaTextActual]);

  const handleCopy = (text) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text).then(() => {
        Swal.fire({
          icon: 'success',
          title: '¡Copiado!',
          text: 'La invitación ha sido copiada al portapapeles.',
          timer: 1500,
          showConfirmButton: false
        });
      }).catch(err => {
        console.error('Error al copiar: ', err);
      });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        Swal.fire({
          icon: 'success',
          title: '¡Copiado!',
          text: 'La invitación ha sido copiada al portapapeles.',
          timer: 1500,
          showConfirmButton: false
        });
      } catch (err) {
        console.error('Error al copiar: ', err);
      }
      document.body.removeChild(textArea);
    }
  };

  return (
    <>
     <audio ref={audioRef} src={locpLay} preload="auto"/>
     <Navbar locpLay={audioRef} imgVolumenShow={imgVolumenShow} musicaText={<a href='/#/MenuInicial'><img src={hamburg}></img></a>}/>
     
     <div className="DivAdmin">
  <h2 className="h1admin">Gracias por compartir este día con nosotros</h2>
  <p>Desde esta página vas a poder acceder cuando quieras a todas las fotos de la boda.<br></br>
  Tan pronto como las tengamos, las subiremos para que puedas recordar este día.</p>
  <br></br>
  <p>Este acceso es personal para ti, {invitados.nombreCompleto}, y es mi intención que siempre esté activo para que puedas volver a ver las fotos de este gran día dentro de 20 años.</p>
  <p><br></br>Disfruta de lo que queda de la noche y recuerda que puedes hacer fotos desde esta página para que queden inmortalizadas.</p>
  <p><br></br><b>De nuevo, muchas gracias por venir.</b></p>
</div>

    </>
  );
};

export default PanelUser;
