// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavInicial{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background: rgb(0, 0, 0); */
   /*  transition: opacity 10500ms;  */
}

.NavOpen_1{
    z-index: 1;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.NavOpen_2{
    z-index: 2;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.textoNav{
    z-index: 3;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 2em;
    bottom: 0;
    left: 8.5em;
    right: 0;
    color: white;
    font-size: 1em;
}

.navmusic{
    z-index: 4;
    position: fixed;
    top: 2em;
    bottom: 0;
    left: 8.5em;
    right: 0;
}`, "",{"version":3,"sources":["webpack://./src/carga/NavInicial.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,SAAS;IACT,OAAO;IACP,QAAQ;IACR,8BAA8B;GAC/B,mCAAmC;AACtC;;AAEA;IACI,UAAU;IACV,WAAW;IACX,YAAY;IACZ,eAAe;IACf,MAAM;IACN,SAAS;IACT,OAAO;IACP,QAAQ;AACZ;AACA;IACI,UAAU;IACV,WAAW;IACX,YAAY;IACZ,eAAe;IACf,MAAM;IACN,SAAS;IACT,OAAO;IACP,QAAQ;AACZ;AACA;IACI,UAAU;IACV,WAAW;IACX,YAAY;IACZ,eAAe;IACf,QAAQ;IACR,SAAS;IACT,WAAW;IACX,QAAQ;IACR,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,UAAU;IACV,eAAe;IACf,QAAQ;IACR,SAAS;IACT,WAAW;IACX,QAAQ;AACZ","sourcesContent":[".NavInicial{\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    /* background: rgb(0, 0, 0); */\n   /*  transition: opacity 10500ms;  */\n}\n\n.NavOpen_1{\n    z-index: 1;\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n}\n.NavOpen_2{\n    z-index: 2;\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n}\n.textoNav{\n    z-index: 3;\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    top: 2em;\n    bottom: 0;\n    left: 8.5em;\n    right: 0;\n    color: white;\n    font-size: 1em;\n}\n\n.navmusic{\n    z-index: 4;\n    position: fixed;\n    top: 2em;\n    bottom: 0;\n    left: 8.5em;\n    right: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
