/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Link from '@mui/joy/Link';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import DownloadIcon from '@mui/icons-material/Download';
import ModeCommentOutlined from '@mui/icons-material/ModeCommentOutlined';
import SendOutlined from '@mui/icons-material/SendOutlined';
import Face from '@mui/icons-material/Face';
import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded';
import AVATARDEFAULT from './../../imagenes/svg/dwight.svg';

export default function InstagramPost(props) {
  /* alert(props.NombreInvitado+" "+ props.avatarinvitado+" "+ props.tipo +" "+ props.foto); */
  const imageUrl = require(`./../../fotos/${props.tipo}/${props.foto}.jpg`);
  
  return (
    <Card variant="soft" color="warning"
    
      /* variant="outlined" */
      sx={{
        minWidth: 300,
        '--Card-radius': (theme) => theme.vars.radius.xs,
      }}
    >
      <CardContent orientation="horizontal" sx={{ alignItems: 'center', gap: 1 }}>
        <Box
          sx={{
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              m: '-2px',
              borderRadius: '50%',
              background:
                'linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)',
            },
          }}
        >
          <Avatar
            size="sm"
            src={require(`./../../imagenes/svg/${props.avatarinvitado}`)}
            sx={{ p: 0, border: '2px solid', borderColor: 'background.body' }}
          />
        </Box>
        <Typography fontWeight="lg">{props.NombreInvitado}</Typography>
        <IconButton variant="plain" color="neutral" size="sm" sx={{ ml: 'auto' }} 
          
          component="a"
          href={imageUrl}
          download={`${props.foto}-${props.tipo}.jpg`}
          >

        <DownloadIcon />
        </IconButton>
      </CardContent>
      <CardOverflow>
          {require(`./../../fotos/${props.tipo}/${props.foto}.jpg`) && <img src={require(`./../../fotos/${props.tipo}/${props.foto}.jpg`)}  alt="" loading="lazy" />}
      </CardOverflow>
      <CardContent>
        {/* <Link
          component="button"
          underline="none"
          fontSize="sm"
          fontWeight="lg"
          textColor="text.primary"
        >
          0 Likes
        </Link> */}{/* 
        <Typography fontSize="sm">
          <Link
            component="button"
            color="neutral"
            fontWeight="lg"
            textColor="text.primary"
          >
            MUI
          </Link>{' '}
          The React component library you always wanted
        </Typography> */}
      </CardContent>
      <CardContent orientation="horizontal" sx={{ gap: 1 }}>
      </CardContent>
    </Card>
  );
}