import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";

import Swal from 'sweetalert2'


import Navbar from './../Navbar/Navbar';
import audiomp3 from './../../audio/outsideintro.mp3';

import hamburg from './../../imagenes/bar-de-hamburguesas.png';

import Cards from './Card';
import './card.css';
const FotoNovia = ({ quitarCarga, IdiomaTextActual, IndexSlide}) => {
  const audioRef = useRef(null);
  const [locpLay, setlocpLay] = useState(audiomp3);
  const [imgVolumenShow, setimgVolumenShow] = useState(true);


  const navigate = useNavigate();

/*   const [idinvitado, seidinvitado] = useState(JSON.parse(localStorage.getItem("invitado"))._id); */
  


  console.log("idiomas", IdiomaTextActual);


  const esperaInvitacion = () => {
    Swal.fire({
      icon: 'error',
      title: '🥲',
      text: IdiomaTextActual.swalnoregistro,
      confirmButtonText: '<i class="fas fa-check"></i> Aceptar',
            confirmButtonColor: '#2ecc71',
            confirmButtonAriaLabel: 'OK'
    }).then(result => {
      if (result.isConfirmed) {
        window.location.replace('https://www.google.com/search?q=GNET+-+Grupalia+Internet+S.A.');
      }
   })



  };


  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('https://bbdd.adrianymarianna.com/fotos/novia')
      .then(response => response.json())
      .then(data => {
        console.log("devuelve: ", data.Fotos);
        setPosts(data.Fotos);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;



  return (
    <>
    <audio ref={audioRef} src={locpLay} preload="auto"/>
    <Navbar className="navmusic" locpLay={audioRef} imgVolumenShow={imgVolumenShow} musicaText={<a href='/#/MenuInicial'><img src={hamburg}></img></a>}/> 
    <div className="fotosFondo">
    {Array.isArray(posts) && posts.map(post => (
    <Cards
    key={post._id}
    NombreInvitado={post.id_invitado.nombreCompleto}
          avatarinvitado={post.id_invitado.id_avatares.imagen1}
          tipo={post.tipo}
          foto={post.nombreFoto}
    ></Cards>   
    
  ))}
  </div>
    </>
  );
};

export default FotoNovia;
