import React, {useRef, useState, useEffect} from 'react'
import {QRCode} from 'react-qrcode-logo';
import { useParams, useNavigate } from "react-router-dom";

const QRCodeWithLogo = ({ url }) => {

  const { id_inv } = useParams();
    console.log("url del QR: ", url);

    const url2 = "https://nuestraboda.adrianymarianna.com/#/galeriaBoda/"+id_inv;
  return <QRCode
        value={url2}
        ecLevel="M"
        quietZone={10}
        logoImage="https://file.adrianymarianna.com/LogoImanesQr.png"
        //logoImage="logoContenido512.png"  
        size="300"
        bgColor="#ffffff"
        fgColor="#000000"
        logoWidth={90} // Ancho del logo en píxeles
        logoHeight={90} // Altura del logo en píxeles
        /* logoOpacity={1} */
        logoPaddingStyle="dots"
        removeQrCodeBehindLogo={true}
        qrStyle="dots"
      />
};

export default QRCodeWithLogo;
