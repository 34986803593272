import React, { useState, useEffect, useRef } from "react";
import GifOnView from './DIVGIF';
import Buttonbus from './BOTONBUS';
import ItinerarioGIF from "./../../imagenes/gif/ItinerarioGIF.gif";
import autobuspt1 from "./../../imagenes/autobuspt1.png";
import autobuspt2 from "./../../imagenes/autobuspt2.png";
import autobuspt3 from "./../../imagenes/autobuspt3.png";
import autobuspt1ani from "./../../imagenes/ANIMACIONBUS1.png";
import autobuspt2ani from "./../../imagenes/ANIMACIONBUS2.png";

import BOTONBUS from "./../../imagenes/svg/BOTONBUS.svg";
import BOTONBUSON from "./../../imagenes/svg/BOTONBUSOK.svg";
import './PLANING.css';
import Navbar from './../../pages/Navbar/Navbar';
import audiomp3 from './../../audio/MarriedLife.mp3';

import Swal from 'sweetalert2'
import { useParams, useNavigate } from "react-router-dom";
import withReactContent from 'sweetalert2-react-content';

import hamburg from './../../imagenes/bar-de-hamburguesas.png';

import { API } from "../../services/api";

const CanvaEmbed = () => {
  const audioRef = useRef(null);
  const [locpLay, setlocpLay] = useState(audiomp3);
const [imgVolumenShow, setimgVolumenShow] = useState(true);
const [invitadosReserva, setinvitadosReserva] = useState("sinReserva");

const [IdiomaTextActual, setIdiomaTextActual] = useState({
  "_id": "6599cac40db07abe9ffb9e96",
  "idioma": "espana",
  "imgNoscasamos": "noscasamos.png",
  "textosCarta": "Queridos amigos, ¡la hora ha llegado!\nAdrián y Majka, por fin casados.\nSerá en Lupiana, el 20 de septiembre,\nfirmando que su amor dure para siempre.\n\nNo falten a este día tan especial,\ncon baile, comida, ¡será genial!\nOs esperamos con cariño y emoción,\npara ser testigos de esta unión.",
  "textosCheckCarta": "Pasar a aceptar las condiciones de asistencia",
  "textoCheck1": "Acepto bailar como si nadie estuviera mirando, incluso si todos lo están haciendo, aunque mis movimientos se asemejen más a un pingüino en patinaje sobre hielo",
  "textoCheck2": "Estoy de acuerdo en reírme hasta que me duela la barriga, aunque mi risa suene como una mezcla entre una hiena y un pato",
  "textoCheck3": "Acepto que las fotos embarazosas son una parte obligatoria de la celebración, y me uniré a la diversión sin quejarme, incluso si eso implica usar accesorios ridículos para fotos grupales",
  "textoCheck4": "Prometo no contar chistes malos, a menos que sean tan malos que se vuelvan buenos por accidente",
  "textoCheck5": "Acepto que la pista de baile será mi gimnasio y bailaré hasta quemar más calorías de las consumidas en la cena",
  "textoCheck6": "Acepto tomarme un chupito de Soplica para no hacerle un feo a la novia",
  "textoCheck7": "Estoy dispuesto a aplaudir, gritar \"vivan los novios\" y vitorear exageradamente, animando a los novios y demás invitados como si estuviera en un estadio deportivo",
  "textoCheck8": "Estoy de acuerdo en que los abrazos y besos son obligatorios, y cualquier intento de resistirse será castigado con abrazos y besos aún más fuertes",
  "textoCheck9": "Prometo no rechazar ninguna oferta de comida, postre o delicioso manjar que se cruce en mi camino durante la celebración",
  "textoCheck10": "Acepto que las risas y los recuerdos compartidos superarán cualquier intento de mantener la dignidad intacta",
  "textoCheck11": "Me comprometo a confirmar mi asistencia en fecha. Si no, me llevaré una silla y un sándwich",
  "textoFailCheckTitle": "Acepta, TODAS las condiciones.",
  "textoFailCheck": "Aqui, hemos venido a jugar.",
  "textoSucesCheckTitle": "Gracias por aceptar las condiciones",
  "textoSucesCheck": "Ahora solo queda que elijas tu avatar.",
  "createdAt": "2024-01-06T21:48:52.020Z",
  "updatedAt": "2024-03-31T14:31:02.328Z",
  "__v": 0,
  "cronometroText": "Faltan",
  "imagenLosdosText": "adrianymajkaSUN.png",
  "cronometroTextSegundos": "Segundos",
  "musicaText": "Activa el sonido",
  "textoTyC": "Condiciones de asistencia",
  "textoCarga": "Tenemos algo importante que deciros...\n¡Nos casamos!\n",
  "imagenSello": "sellocartaLogo.svg",
  "imagenSelloM": "sellocartaLogoON.svg",
  "buttonfirmaguardar": "Guardar",
  "buttonfirmalimpiar": "Limpiar",
  "buttonswalfirmacancel": "Cerrar",
  "cargamusicatext": "¡Sube el volumen!",
  "iconoContinuar": "BTNMUSICESP.png",
  "iconoContinuarsvg": "BTNMUSICESP.svg",
  "inputtextarea": "¿Se te ocurre algún otro avatar? ¡Dinos cuál! Recuerda que la temática es: flores, plantas…",
  "textformlargo": "Todavía estamos ultimando algunos detalles de la boda. Por ello, necesitamos que nos confirmes tus datos de contacto, para poder avisarte de nuevas noticias sobre el evento.",
  "textoinput1": "Móvil:",
  "textoinput2": "Email:",
  "textswalsininv": "Debes acceder por el enlace que te hemos enviado. Lo siento.",
  "titleswalfirma": "Necesitamos una firmita por aqui…",
  "titleswalsininv": "No se quién eres\n\n😷\n",
  "buttonconfirmacion": "Confirmar asistencia",
  "texttemporizador": "¿Cuánto falta para el gran día?",
  "texttemporizadorsegundos": "Tan solo faltan",
  "textlobyfinal": "Os iremos avisando tan pronto tengamos todos los detalles del evento. Mientras tanto, por favor, esperad aquí junto al resto de invitados.",
  "swalnoregistro": "Esperamos que puedas venir",
  "textoMorfeo": "Tomas la píldora azul... la historia termina, te despiertas en tu cama y crees lo que quieras creer. Tomas la píldora roja... te quedas en el País de las Maravillas, y te enseño lo profunda que es la madriguera del conejo",
  /* "videoRick": "rickadmortyvideo.mp4", */
  "titlealertseleccion": "Toca elegir el avatar.",
"mensajealertseleccion": "Recuerda que tienes varios para elegir, tomate tu tiempo.",
"botonalertseleccion": "Aceptar",
"botonalertavatar": "Lo quiero!",
"titlealertavatar": "¿Quieres este avatar?"
});

useEffect(() => {
  if(localStorage.getItem("invitado")){
    /* if(JSON.parse(localStorage.getItem("invitado"))._id == "664f43c02271de7b324ad38c" || JSON.parse(localStorage.getItem("invitado"))._id == "664eeb142271de7b324ad308"){
      setcargaVideoShow(false);
    } */

      const getCancionesId = async () => {
        const userInv =  await API.get('/invitados/'+JSON.parse(localStorage.getItem("invitado"))._id).then((res) => {
          console.log("respuesta",res);
          setinvitadosReserva(res.data.invitados.reservaBus);
        })
      };
      getCancionesId();
    
    if(localStorage.getItem("textos")){

      const filtrarPorIdioma = (elemento) => elemento.idioma === JSON.parse(localStorage.getItem("invitado")).idioma;
      const arrayTextos = JSON.parse(localStorage.getItem('textos'));
      const resultado = arrayTextos.filter(filtrarPorIdioma);
      console.log("texto de bbdd: ", resultado[0]);
      setIdiomaTextActual(resultado[0]);
    }

  }else{
    Swal.fire({
      icon: 'error',
      title: IdiomaTextActual.titleswalsininv,
      text: IdiomaTextActual.textswalsininv,
      confirmButtonText: IdiomaTextActual.buttonswalsininv
    }).then(result => {
      if (result.isConfirmed) {
        window.location.replace('https://www.google.com/search?q=GNET+-+Grupalia+Internet+S.A.');
      }
   })
    
  }
  
}, []);


  return (
    <>
    <audio ref={audioRef} src={locpLay} preload="auto"/>
      <Navbar locpLay={audioRef} imgVolumenShow={imgVolumenShow} musicaText={<a href='/#/MenuInicial'><img src={hamburg}></img></a>}/>
    <div>
      <GifOnView
        src={ItinerarioGIF}
        alt="itinerario 1"
        className="myGifClass"
      />
      <div className="mydivAni">
      <GifOnView
        src={autobuspt1ani}
        alt="itinerario GIF"
        className="myGifClassAni"
      />
      <img
        src={autobuspt1}
        alt="itinerario 2"
        className="myGifClass"
      />
      </div>
      <div className="mydivAni">
      <img
        src={autobuspt2ani}
        alt="itinerario GIF"
        className="myGifClassAni"
      />
      <img
        src={autobuspt2}
        alt="itinerario 3"
        className="myGifClass"
      />
      </div>
      <div className="contenedorIMG">
      <img
        src={autobuspt3}
        alt="itinerario 4"
        className="myGifClassfooter"
      />
      <Buttonbus
        src={{bus: BOTONBUS, buson: BOTONBUSON}}
        alt="busBoton"
        className="botonbussclass"
        estadoInvitado={invitadosReserva}
      />
      <div className="contenedorFondoBoton"><br></br><br></br><br></br><br></br><p>Reserva plaza en el autobús</p></div>
      </div>

    </div>
    </>




  );
};

export default CanvaEmbed;

