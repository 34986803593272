import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { API } from "../../services/api";
import { useParams, useNavigate } from "react-router-dom";

const GifOnView = ({ src, alt, className, estadoInvitado }) => {

  const navigate = useNavigate();

  useEffect(() => {
  }, []);

  const  reservarbus = (idinvitado) => {
    const MySwal = withReactContent(Swal);
    if(estadoInvitado != "sinReserva"){
      MySwal.fire({
        title: "Tu asiento ya esta reservado, quieres anularlo?",
        html: '<div class="condicionesT">'+
        '<div class="condicionT">'+
        '<input class="inputT" value="sinReserva" type="radio" id="terms4" name="autobus">' +
        '<label class="labelT" for="terms">Anular asiento</label><br>' +
        '</div>'+
        '</div>',
        showCancelButton: true,
        cancelButtonText: "cancelar",
        showConfirmButton: true,
        preConfirm: () => {
          return{
              terms4: document.getElementById('terms4'),
          }
        }
      }).then(result => {
        if (result.isConfirmed) {
          if(result.value.terms4.checked){
  
            API.patch('/invitados/'+JSON.parse(localStorage.getItem("invitado"))._id, {
              "reservaBus": "sinReserva"
          }).then(
            navigate("/menuInicial")
          ).then(
            setTimeout(() => window.scrollTo({
              top: 0,
              behavior: 'smooth' // Desplazamiento suave
          }), 1000)
            )
          } else {
              Swal.fire({
                  icon: 'error',
                  title: "No anulastes tu asiento",
                  text: "Seleccione anular asiento"
                })
          }
          
        }
     });
    }else{
    
     MySwal.fire({
      title: "Reserva de asiento:",
      html: '<div class="condicionesT">'+
      '<div class="condicionT">'+
      '<input class="inputT" value="Leganes" type="radio" id="terms1" name="autobus">' +
      '<label class="labelT" for="terms">Desde Leganés</label><br>' +
      '</div>'+
      '<div class="condicionT">'+
      '<input class="inputT" value="Alcala" type="radio" id="terms2" name="autobus">' +
      '<label class="labelT" for="terms">Desde Alcalá de Henares</label><br>'+
      '</div>'+
      '<div class="condicionT">'+
      '<input class="inputT" value="Hotel" type="radio" id="terms3" name="autobus">' +
      '<label class="labelT" for="terms">Desde el Hotel</label><br>'+
      '</div>'+
      '</div>',
      showCancelButton: true,
      cancelButtonText: "cancelar",
      showConfirmButton: true,
      preConfirm: () => {
        return{
            terms: document.getElementById('terms1'),
            terms2: document.getElementById('terms2'),
            terms3: document.getElementById('terms3'),
            
        }
      }
    }).then(result => {
      if (result.isConfirmed) {
        if(result.value.terms.checked){

          API.patch('/invitados/'+JSON.parse(localStorage.getItem("invitado"))._id, {
            "reservaBus": "Leganes"
        }).then(
          navigate("/menuInicial")
        ).then(
          setTimeout(() => window.scrollTo({
            top: 0,
            behavior: 'smooth' // Desplazamiento suave
        }), 1000)
          ).then(
          Swal.fire({
            icon: 'success',
            title: '!Asiento reservado¡',
            text: 'Asiento reservado desde Leganés'
          })
        )

        } else if (result.value.terms2.checked) {
          API.patch('/invitados/'+JSON.parse(localStorage.getItem("invitado"))._id, {
            "reservaBus": "Alcala"
        }).then(
          navigate("/menuInicial")
        ).then(
          setTimeout(() => window.scrollTo({
            top: 0,
            behavior: 'smooth' // Desplazamiento suave
        }), 1000)
          ) /* .then(
          setTimeout(() => window.location.reload(), 1000) 
        ) */.then(
          Swal.fire({
            icon: 'success',
            title: '!Asiento reservado¡',
            text: 'Asiento reservado desde Alcalá de Henares'
          })
        )

        } else if (result.value.terms3.checked) {
          API.patch('/invitados/'+JSON.parse(localStorage.getItem("invitado"))._id, {
            "reservaBus": "Hotel"
        }).then(
          navigate("/menuInicial")
        ).then(
          setTimeout(() => window.scrollTo({
            top: 0,
            behavior: 'smooth' // Desplazamiento suave
        }), 1000)
          ).then(
          Swal.fire({
            icon: 'success',
            title: '!Asiento reservado¡',
            text: 'Asiento reservado desde el hotel'
          })
        )

        } else {
            Swal.fire({
                icon: 'error',
                title: "Sin asiento en el bus",
                text: "No elegiste ninguna localizacion"
              })
        }
        
      }
   });
  }
  }
  return (
    <img 
      src={estadoInvitado == "sinReserva" ? src.bus : src.buson}
      alt={alt}
      className={className}
      onClick={() => reservarbus(JSON.parse(localStorage.getItem("invitado"))._id)}
    />
  );
};

export default GifOnView;
