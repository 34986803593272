// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    .myGifClass{
      width: 100%;/* 
      position: absolute;
  top: 0;
  left: 0;
  z-index: 1; /* Más bajo para estar por debajo */ 
    }

    .myGifClassfooter{
      width: 100%;
      
      height: auto; /* Mantiene las proporciones */
  object-fit: cover; /* Asegura que la imagen cubra el contenedor */


    }
    .mydivAni{
      position: relative;
    }

   
    
    .myGifClassAni{
      position: absolute;
      width: 100%;
  top: 0;
  right: 0;
  z-index: 2; /* Más alto para estar por encima */

    }

    .contenedorIMG{
      position: relative;
      height: 150px; /* Muestra la mitad del ancho de la imagen */
  overflow: hidden; /* Oculta el resto de la imagen */
      

    }
    .botonbussclass{
      width: 30%;
      position: absolute;
      top: 10%;
  right: 35%;
  z-index: 2; /* Más alto para estar por encima */
    }
    .contenedorFondoBoton{
      position: absolute;
      top: 170px;
      left: 50%;
      transform: translate(-50%, -50%);
  z-index: 1;
  background-color: rgb(12, 12, 100);
  border-radius: 100%;
  width: 300px;
  height: 300px;
  text-align: center;
  color: white;
  

    }`, "",{"version":3,"sources":["webpack://./src/pages/CANVAS/PLANING.css"],"names":[],"mappings":";IACI;MACE,WAAW,CAAC;;;;iDAI+B;IAC7C;;IAEA;MACE,WAAW;;MAEX,YAAY,EAAE,8BAA8B;EAChD,iBAAiB,EAAE,8CAA8C;;;IAG/D;IACA;MACE,kBAAkB;IACpB;;;;IAIA;MACE,kBAAkB;MAClB,WAAW;EACf,MAAM;EACN,QAAQ;EACR,UAAU,EAAE,mCAAmC;;IAE7C;;IAEA;MACE,kBAAkB;MAClB,aAAa,EAAE,4CAA4C;EAC/D,gBAAgB,EAAE,iCAAiC;;;IAGjD;IACA;MACE,UAAU;MACV,kBAAkB;MAClB,QAAQ;EACZ,UAAU;EACV,UAAU,EAAE,mCAAmC;IAC7C;IACA;MACE,kBAAkB;MAClB,UAAU;MACV,SAAS;MACT,gCAAgC;EACpC,UAAU;EACV,kCAAkC;EAClC,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,YAAY;;;IAGV","sourcesContent":["\n    .myGifClass{\n      width: 100%;/* \n      position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 1; /* Más bajo para estar por debajo */ \n    }\n\n    .myGifClassfooter{\n      width: 100%;\n      \n      height: auto; /* Mantiene las proporciones */\n  object-fit: cover; /* Asegura que la imagen cubra el contenedor */\n\n\n    }\n    .mydivAni{\n      position: relative;\n    }\n\n   \n    \n    .myGifClassAni{\n      position: absolute;\n      width: 100%;\n  top: 0;\n  right: 0;\n  z-index: 2; /* Más alto para estar por encima */\n\n    }\n\n    .contenedorIMG{\n      position: relative;\n      height: 150px; /* Muestra la mitad del ancho de la imagen */\n  overflow: hidden; /* Oculta el resto de la imagen */\n      \n\n    }\n    .botonbussclass{\n      width: 30%;\n      position: absolute;\n      top: 10%;\n  right: 35%;\n  z-index: 2; /* Más alto para estar por encima */\n    }\n    .contenedorFondoBoton{\n      position: absolute;\n      top: 170px;\n      left: 50%;\n      transform: translate(-50%, -50%);\n  z-index: 1;\n  background-color: rgb(12, 12, 100);\n  border-radius: 100%;\n  width: 300px;\n  height: 300px;\n  text-align: center;\n  color: white;\n  \n\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
