import React, { useState, useEffect, useRef } from "react";
import hamburg from './../../imagenes/bar-de-hamburguesas.png';
import Navbar from './../../pages/Navbar/Navbar';
import audiomp3 from './../../audio/MarriedLife.mp3';
const WeddingInfo = () => {
    const audioRef = useRef(null);
  const [locpLay, setlocpLay] = useState(audiomp3);
const [imgVolumenShow, setimgVolumenShow] = useState(true);
  return (
    <>
    <audio ref={audioRef} src={locpLay} preload="auto"/>
    <Navbar locpLay={audioRef} imgVolumenShow={imgVolumenShow} musicaText={<a href='/#/MenuInicial'><img src={hamburg}></img></a>}/>
    <div style={{ fontFamily: 'Arial, sans-serif', lineHeight: '1.6', padding: '20px', backgroundColor: 'wheat' }}>
      <h2>¡El mejor regalo eres tú!</h2>
      <p>
        El mejor regalo es tenerte en nuestra boda, que nos acompañes en un día tan especial y disfrutes con nosotros.
        Pero si quieres contribuir a nuestro nuevo camino juntos, aquí te dejamos nuestro número de cuenta:
      </p>
      <p style={{ fontWeight: 'bold', fontSize: '1.2em' }}>ES12 1465 0100 9520 5438 8905</p>
      <p>¡Gracias por ser parte de nuestra locura! ♥️</p>

      <h2>Comodidad ante todo</h2>
      <p>
        Porque sabemos que los tacones cansan y las noches se enfrían, ¡habrá alpargatas de diferentes tallas y
        pashminas para que nada te detenga! Si quieres llevarte algo para el frío, no te preocupes que hay ropero.
        <br></br>
        👠🧣
      </p>

      <h2>¿Necesitas una peluquería cerca del hotel?</h2>
      <p>
        Si quieres estar aún más radiante para la ocasión, puedes preguntar en la siguiente peluquería. Está cerquita del
        Hotel Pax, y ofrecen muchos servicios. ¡Te sentirás espectacular! 💇🏼‍♀️💄💅🏼
      </p>
      <p>
        <strong>Blu Estilistas</strong>
        <br />
        <a
          href="https://www.google.com/maps/place/Blu+Estilistas/@40.6316842,-3.1582164,16z/data=!4m10!1m3!4m2!1m0!1m0!3m5!1s0xd43ab0a863d641b:0xa0eb223afd82c9a!8m2!3d40.6316842!4d-3.1556415!16s%2Fg%2F11cmcyk025?entry=ttu&g_ep=EgoyMDI0MDkwNC4wIKXMDSoASAFQAw%3D%3D"
          target="_blank"
          rel="noopener noreferrer"
        >
          Maps del sitio
        </a>
        
      </p>

      <h2>¿Dónde aparco?</h2>
      <p>
        Si vas en coche a la Iglesia de San Pedro Apóstol de Lupiana, tendrás que aparcar en la <a
          href="https://www.google.es/maps/place/Pl.+Mayor,+19142+Lupiana,+Guadalajara/@40.6104502,-3.0556264,17z/data=!3m1!4b1!4m6!3m5!1s0xd4301cbefe0b797:0xb2e95ac34b1214d6!8m2!3d40.6104462!4d-3.0530515!16s%2Fg%2F11bwfx6ysh?entry=ttu&g_ep=EgoyMDI0MDkxMC4wIKXMDSoASAFQAw%3D%3D"
          target="_blank"
          rel="noopener noreferrer"
        >Plaza Mayor de Lupiana</a>. En el <a
          href="https://www.google.es/maps/place/Monasterio+de+Lupiana/@40.6104502,-3.0556264,17z/data=!4m6!3m5!1s0xd4301555ccbafdd:0x59be30d6b0b645a8!8m2!3d40.6009415!4d-3.0528088!16s%2Fg%2F11fc9yhnd5?entry=ttu&g_ep=EgoyMDI0MDkxMC4wIKXMDSoASAFQAw%3D%3D"
          target="_blank"
          rel="noopener noreferrer"
        >Monasterio de San Bartolomé de Lupiana</a> habrá alguien que te indique dónde aparcar. 🚗
      </p>

      <h2>¿Necesitas un taxi?</h2>
      <p>
        Si necesitas un taxi, aquí tienes información de dos contactos:
        <br></br>
        Taxi Yebes <a href="tel:680615152">680615152</a>. (Preguntar por Miguel)
        <br></br>
        Radio taxi Guadalajara <a href="tel:949212245">949212245</a>.
      </p>
    </div>
    </>
  );
};

export default WeddingInfo;
