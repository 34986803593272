import React from 'react';

import Firma from '../pages/Registro/Firma';

const Pruebas = () => {
  return (
      <Firma
      />
  );
};

export default Pruebas;