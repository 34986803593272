import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios';
import { API } from "../../services/api";
import Swal from 'sweetalert2'
import { useParams, useNavigate } from "react-router-dom";
import withReactContent from 'sweetalert2-react-content';


import './BuscarCancion.css';
import Navbar from './../../pages/Navbar/Navbar';
import audiomp3 from './../../audio/sonidonaturaleza.mp3';
import hamburg from './../../imagenes/bar-de-hamburguesas.png';
function App() {
  const [query, setQuery] = useState('');
  const [tracks, setTracks] = useState([]);
  const [Canciones, setCanciones] = useState(false);

  const [cancionesTabla, setCancionesTabla] = useState([]);

  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  const [IdiomaTextActual, setIdiomaTextActual] = useState({
    "_id": "6599cac40db07abe9ffb9e96",
    "idioma": "espana",
    "imgNoscasamos": "noscasamos.png",
    "textosCarta": "Queridos amigos, ¡la hora ha llegado!\nAdrián y Majka, por fin casados.\nSerá en Lupiana, el 20 de septiembre,\nfirmando que su amor dure para siempre.\n\nNo falten a este día tan especial,\ncon baile, comida, ¡será genial!\nOs esperamos con cariño y emoción,\npara ser testigos de esta unión.",
    "textosCheckCarta": "Pasar a aceptar las condiciones de asistencia",
    "textoCheck1": "Acepto bailar como si nadie estuviera mirando, incluso si todos lo están haciendo, aunque mis movimientos se asemejen más a un pingüino en patinaje sobre hielo",
    "textoCheck2": "Estoy de acuerdo en reírme hasta que me duela la barriga, aunque mi risa suene como una mezcla entre una hiena y un pato",
    "textoCheck3": "Acepto que las fotos embarazosas son una parte obligatoria de la celebración, y me uniré a la diversión sin quejarme, incluso si eso implica usar accesorios ridículos para fotos grupales",
    "textoCheck4": "Prometo no contar chistes malos, a menos que sean tan malos que se vuelvan buenos por accidente",
    "textoCheck5": "Acepto que la pista de baile será mi gimnasio y bailaré hasta quemar más calorías de las consumidas en la cena",
    "textoCheck6": "Acepto tomarme un chupito de Soplica para no hacerle un feo a la novia",
    "textoCheck7": "Estoy dispuesto a aplaudir, gritar \"vivan los novios\" y vitorear exageradamente, animando a los novios y demás invitados como si estuviera en un estadio deportivo",
    "textoCheck8": "Estoy de acuerdo en que los abrazos y besos son obligatorios, y cualquier intento de resistirse será castigado con abrazos y besos aún más fuertes",
    "textoCheck9": "Prometo no rechazar ninguna oferta de comida, postre o delicioso manjar que se cruce en mi camino durante la celebración",
    "textoCheck10": "Acepto que las risas y los recuerdos compartidos superarán cualquier intento de mantener la dignidad intacta",
    "textoCheck11": "Me comprometo a confirmar mi asistencia en fecha. Si no, me llevaré una silla y un sándwich",
    "textoFailCheckTitle": "Acepta, TODAS las condiciones.",
    "textoFailCheck": "Aqui, hemos venido a jugar.",
    "textoSucesCheckTitle": "Gracias por aceptar las condiciones",
    "textoSucesCheck": "Ahora solo queda que elijas tu avatar.",
    "createdAt": "2024-01-06T21:48:52.020Z",
    "updatedAt": "2024-03-31T14:31:02.328Z",
    "__v": 0,
    "cronometroText": "Faltan",
    "imagenLosdosText": "adrianymajkaSUN.png",
    "cronometroTextSegundos": "Segundos",
    "musicaText": "Activa el sonido",
    "textoTyC": "Condiciones de asistencia",
    "textoCarga": "Tenemos algo importante que deciros...\n¡Nos casamos!\n",
    "imagenSello": "sellocartaLogo.svg",
    "imagenSelloM": "sellocartaLogoON.svg",
    "buttonfirmaguardar": "Guardar",
    "buttonfirmalimpiar": "Limpiar",
    "buttonswalfirmacancel": "Cerrar",
    "cargamusicatext": "¡Sube el volumen!",
    "iconoContinuar": "BTNMUSICESP.png",
    "iconoContinuarsvg": "BTNMUSICESP.svg",
    "inputtextarea": "¿Se te ocurre algún otro avatar? ¡Dinos cuál! Recuerda que la temática es: flores, plantas…",
    "textformlargo": "Todavía estamos ultimando algunos detalles de la boda. Por ello, necesitamos que nos confirmes tus datos de contacto, para poder avisarte de nuevas noticias sobre el evento.",
    "textoinput1": "Móvil:",
    "textoinput2": "Email:",
    "textswalsininv": "Debes acceder por el enlace que te hemos enviado. Lo siento.",
    "titleswalfirma": "Necesitamos una firmita por aqui…",
    "titleswalsininv": "No se quién eres\n\n😷\n",
    "buttonconfirmacion": "Confirmar asistencia",
    "texttemporizador": "¿Cuánto falta para el gran día?",
    "texttemporizadorsegundos": "Tan solo faltan",
    "textlobyfinal": "Os iremos avisando tan pronto tengamos todos los detalles del evento. Mientras tanto, por favor, esperad aquí junto al resto de invitados.",
    "swalnoregistro": "Esperamos que puedas venir",
    "textoMorfeo": "Tomas la píldora azul... la historia termina, te despiertas en tu cama y crees lo que quieras creer. Tomas la píldora roja... te quedas en el País de las Maravillas, y te enseño lo profunda que es la madriguera del conejo",
    /* "videoRick": "rickadmortyvideo.mp4", */
    "titlealertseleccion": "Toca elegir el avatar.",
"mensajealertseleccion": "Recuerda que tienes varios para elegir, tomate tu tiempo.",
"botonalertseleccion": "Aceptar",
"botonalertavatar": "Lo quiero!",
"titlealertavatar": "¿Quieres este avatar?"
  });

  const [locpLay, setlocpLay] = useState(audiomp3);
  const [imgVolumenShow, setimgVolumenShow] = useState(true);

  const audioRef = useRef(null);
  
  const getAuthToken = async () => {
    const clientId = '48ae384111fd4ccb804b2a56516afc0b';
    const clientSecret = '3858f85646d64c0b9fff2abee8a3c891';
    const response = await axios('https://accounts.spotify.com/api/token', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic ' + btoa(clientId + ':' + clientSecret)
      },
      data: 'grant_type=client_credentials',
      method: 'POST'
    });
    return response.data.access_token;
  };

  const searchTracks = async (query) => {
    const token = await getAuthToken();
    const response = await axios(`https://api.spotify.com/v1/search?q=${query}&type=track`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    setTracks(response.data.tracks.items);
    console.log("datos:", response.data)
  };

  const handleSearch = (e) => {
    e.preventDefault();
    searchTracks(query);
  };


  useEffect(() => {
    if(localStorage.getItem("invitado")){
      /* if(JSON.parse(localStorage.getItem("invitado"))._id == "664f43c02271de7b324ad38c" || JSON.parse(localStorage.getItem("invitado"))._id == "664eeb142271de7b324ad308"){
        setcargaVideoShow(false);
      } */

        const getCancionesId = async () => {
          const userInv =  await API.get('/invitados/cancionesInv/'+JSON.parse(localStorage.getItem("invitado"))._id).then((res) => {
            console.log("respuesta",res);
            setCanciones(res.data.invitados.votoCancion);
          }).then(valorTabla => {
            API.get('canciones/contador').then((response) =>{
              console.log("canciones  ", response.data.Canciones)
              setCancionesTabla(response.data.Canciones);
            }
              
            );
          }
          )
        };
        getCancionesId();
      
      if(localStorage.getItem("textos")){

        const filtrarPorIdioma = (elemento) => elemento.idioma === JSON.parse(localStorage.getItem("invitado")).idioma;
        const arrayTextos = JSON.parse(localStorage.getItem('textos'));
        const resultado = arrayTextos.filter(filtrarPorIdioma);
        console.log("texto de bbdd: ", resultado[0]);
        setIdiomaTextActual(resultado[0]);
      }

    }else{
      Swal.fire({
        icon: 'error',
        title: IdiomaTextActual.titleswalsininv,
        text: IdiomaTextActual.textswalsininv,
        confirmButtonText: IdiomaTextActual.buttonswalsininv
      }).then(result => {
        if (result.isConfirmed) {
          window.location.replace('https://www.google.com/search?q=GNET+-+Grupalia+Internet+S.A.');
        }
     })
      
    }
    
  }, []);





  const votarCancion = async (id, name, artista) => {
    API.post('/canciones', {
      "name": name,
      "artista": artista,
      "id": id
  })
  .then(response => {
    console.log("RESPON POST canciones", response);
    
    API.patch('/invitados/'+JSON.parse(localStorage.getItem("invitado"))._id, {
      "votoCancion": true
  }).then(
    
      MySwal.fire({
       /* title: IdiomaTextActual.titleswalfirma,
       html: <Firma navigate={navigate("/loby")} IdiomaTextActual={IdiomaTextActual}/>,
       showCancelButton: true,
       cancelButtonText: IdiomaTextActual.buttonswalfirmacancel,
       showConfirmButton: false, */
       title: "cancion elegida",
       html: <h1>🌈🌈</h1>,
       showCancelButton: false,
       showConfirmButton: true,
     }).then(result => {
      if (result.isConfirmed) {
        window.location.reload();
      }
   }

     )

  );

  }).catch(error => {
    Swal.fire({
      icon: 'error',
      title: 'Hubo un error de conexión',
      text: 'Revisa si tienes conexión a internet y vuelve a enviarme la información.'
    })
    console.error(error);
  });
  }

  const oirCancion =  (cancionid) => {
    MySwal.fire({
      /* title: IdiomaTextActual.titleswalfirma,
      html: <Firma navigate={navigate("/loby")} IdiomaTextActual={IdiomaTextActual}/>,
      showCancelButton: true,
      cancelButtonText: IdiomaTextActual.buttonswalfirmacancel,
      showConfirmButton: false, */
      title: "cancion elegida",
      html: <iframe src={`https://open.spotify.com/embed/track/${cancionid}?utm_source=generator`} width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>,
      showCancelButton: false,
      showConfirmButton: true,
    })
  };

 

  return (
    <>
    <audio ref={audioRef} src={locpLay} preload="auto"/>
    <Navbar locpLay={audioRef} imgVolumenShow={imgVolumenShow} musicaText={<a href='/#/MenuInicial'><img src={hamburg}></img></a>}/>
    {Canciones ? (
      <div className="DivAdmin">
      <h1 className="h1admin">Lista de canciones</h1>
      <table className="tableAdmin">
        <thead>
          <tr>
            <th className="thadmin">Votos</th>
            <th className="thadmin">Nombre</th>
            <th className="thadmin">Cancion</th>
          </tr>
        </thead>
        <tbody className="tbodyadmin">
          {Canciones && cancionesTabla.map((cancionTablarow, index) => (
            <tr key={index}>
              <td className="tdAdmin">{cancionTablarow.count}</td>
              <td className="tdAdmin">{`${cancionTablarow._id.nombre} - ${cancionTablarow._id.artista}`}</td>
              <td className="tdAdmin"><a onClick={() => oirCancion(cancionTablarow.id[0])} >🎵</a></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    ) : (
    <div className="App">
      <h1>Lista de canciones para la boda</h1>
      <p>Estamos preparando una lista de reproduccion para la boda y queremos que participes, tienes un voto por lo que piensalo bien antes de elegir cancion:</p>
      <form onSubmit={handleSearch}>
        <input
          type="text"
          className="form__input"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Busca canciones..."
        /> 
        <button type="submit" className="form__button">Buscar</button>
      </form>
      {tracks.map((track) => (
        <div>
          <iframe key={track.id} src={`https://open.spotify.com/embed/track/${track.id}?utm_source=generator`} width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
          <button className="form__button_green" onClick={() => votarCancion(track.id, track.name, track.artists.map((artist) => artist.name).join(', '))}>+1: {track.name} - {track.artists.map((artist) => artist.name).join(', ')}</button>
        </div>
       ))}
      {/* <ul>
        {tracks.map((track) => (
          <li key={track.id}>
            {track.name} - {track.artists.map((artist) => artist.name).join(', ')}
          </li>
          
        ))}
      </ul> */}
      
    </div>)}
    </> 
  );
}

export default App;
