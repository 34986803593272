import React, { useRef, useState, useEffect } from 'react';
import './Camera.css';
import Grid from '@mui/joy/Grid';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import CollectionsIcon from '@mui/icons-material/Collections';
import CameraIcon from '@mui/icons-material/Camera';

import Swal from 'sweetalert2'; // Importa SweetAlert2

import { API } from "../../services/api";

import { useParams, useNavigate } from "react-router-dom";

import imageCompression from 'browser-image-compression';

import Navbar from './../Navbar/Navbar';
import audiomp3 from './../../audio/outsideintro.mp3';

import hamburg from './../../imagenes/bar-de-hamburguesas.png';

const Camera = () => {
  const audioRef = useRef(null);
  const [locpLay, setlocpLay] = useState(audiomp3);
  const [imgVolumenShow, setimgVolumenShow] = useState(true);


  const videoRef = useRef(null);
  const photoRef = useRef(null);
  const [hasPhoto, setHasPhoto] = useState(false);
  const [facingMode, setFacingMode] = useState('user'); // 'user' para la cámara frontal, 'environment' para la trasera

  const [isIOS, setIsIOS] = useState(false);


  const navigate = useNavigate();

  useEffect(() => {
    getVideo();

    // Detectar si el dispositivo es iOS
    const iOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    setIsIOS(iOSDevice);
    /* alert("Useragent: "+ navigator.userAgent + " entonces es un equipo ios:" + iOSDevice); */
    /* console.log("esta variable: ", isIOS); */

    if(iOSDevice){
      console.log("iphone");
      
      Swal.fire('Error', 'Lamentablemente tienes un Iphone, eres tan exclusivo y cool que tu movil no permite integrar una camara por web por lo que a diferencia del resto de moviles de la boda tu tienes esta caca de interfaz para realizar las fotos. Pulsa "Seleccionar archivo", selecciona la camara y realiza una foto. Luego pulsa sobre el icono siguiente para guardarla. Muchas gracias y la proxima vez no te gastes tanto dinero en el movil', 'error');
      
    }else{
      console.log("android");
      getVideo();
    }

  }, [facingMode]);
  //iphone
 /*  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'environment', // Usar la cámara trasera
          width: { ideal: 1280 },
          height: { ideal: 720 },
        },
        audio: false
      });

      let video = videoRef.current;
      if ('srcObject' in video) {
        video.srcObject = stream;
      } else {
        video.src = window.URL.createObjectURL(stream);
      }

      video.play();
    } catch (err) {
      console.error('Error al acceder a la cámara: ', err);
    }
  }; */



  //android
  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: facingMode } })
      .then(stream => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.onloadedmetadata = () => {
          video.play();
        };
      })
      .catch(err => {
        console.error("Error al acceder a la cámara: ", err);
      });
  };

  const takePhoto = () => {
    const width = 700;
    const height = 900;

    let video = videoRef.current;
    let photo = photoRef.current;

    photo.width = width;
    photo.height = height;

    let ctx = photo.getContext('2d');
    ctx.drawImage(video, 0, 0, width, height);

    const imageData = photo.toDataURL('image/jpeg', 0.3); // Obtén la imagen como Data URL

    // Muestra la alerta con la imagen capturada
    Swal.fire({
      title: 'Foto capturada',
      text: "¿Deseas guardar la foto o descartarla?",
      imageUrl: imageData,
      imageAlt: 'Foto capturada',
      showCancelButton: true,
      confirmButtonText: 'Guardar',
      cancelButtonText: 'Descartar',
      preConfirm: () => savePhoto(imageData), // Llama a la función para guardar la foto
    }).then((result) => {
      if (!result.isConfirmed) {
        closePhoto(); // Si se descarta, limpia la foto
      }
    });


    setHasPhoto(true);
  };

  const savePhoto = async (imageData) => {
    try {
      const response = await API.post('/fotos/upload', {
       image: imageData}
      ).then(response => {
        console.log("Respuesta server: ", response);
      /* if (response.statusText == "OK") { */
        if (true) {
        const responseSave = API.post('/fotos/', {
          "nombreFoto": `${response.data.path}`,
			    "tipo": "camara",
			    "id_invitado": JSON.parse(localStorage.getItem("invitado"))._id
        })

        Swal.fire('¡Guardado!', 'Tu foto ha sido guardada.', 'success');
      } else {
        Swal.fire('Error', 'Hubo un problema al guardar la foto.', 'error');
      }
      })
    } catch (error) {
      if(isIOS){Swal.fire('¡Guardado!', 'Tu foto ha sido guardada.', 'success');}else{Swal.fire('Error', 'No se pudo conectar con el servidor. Almenos que seas un iphone 🥲', 'error');}
      
    }
  };

  const closePhoto = () => {
    navigate("/GaleriaCamara");
  };

  const switchCamera = () => {
    setFacingMode(facingMode === 'user' ? 'environment' : 'user');
  };

  //iios conf
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      Swal.fire('Error', 'No has seleccionado ningún archivo.', 'error');
      return;
    }
    const base64Image = await resizeImage(selectedFile, 800, 600);
    console.log("foto", base64Image);
    await savePhoto(base64Image);
  };

 /*  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }; */

  const resizeImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          // Mantener la proporción original de la imagen
          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height *= maxWidth / width));
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width *= maxHeight / height));
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          // Convertimos la imagen del canvas a Base64 y reducimos la calidad
          const base64Image = canvas.toDataURL('image/jpeg', 0.3); // 0.7 es la calidad (puedes ajustarla entre 0 y 1)
          resolve(base64Image);
        };
      };
      reader.onerror = (error) => reject(error);
    });
  };
  return (
    !isIOS ? (<>
    <audio ref={audioRef} src={locpLay} preload="auto"/>
    <Navbar className="navmusic" locpLay={audioRef} imgVolumenShow={imgVolumenShow} musicaText={<a href='/#/MenuInicial'><img src={hamburg}></img></a>}/>
    <div className="contenedorCamara">
    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
  <Grid xs={4} alignItems="center">
  </Grid>
  <Grid container xs={4} justifyContent="center"  alignItems="center">
  {/* <button onClick={switchCamera}>
          Switch to {facingMode === 'user' ? 'Trasera' : 'frontal'} 
        </button> */}
        <item><FlipCameraAndroidIcon onClick={switchCamera}></FlipCameraAndroidIcon></item>
  </Grid>
  <Grid xs={4} alignItems="center">
  </Grid>
  <Grid xs={12} alignItems="center">
  <video className="VideoFrame" ref={videoRef}></video>
  </Grid>
  <Grid xs={4} alignItems="center">
  </Grid>
  <Grid container xs={4} justifyContent="center"  alignItems="center" >
    <CameraIcon fontSize="large" onClick={takePhoto}></CameraIcon>
  {/* <button onClick={takePhoto}>Foto</button> */}
  </Grid>
  <Grid xs={4} alignItems="center">
  </Grid>
  <Grid xs={4} alignItems="center">
  </Grid>
  <Grid container xs={4} justifyContent="center"  alignItems="center" >
    Foto
  </Grid>
  <Grid xs={4} alignItems="center">
  </Grid>
  <Grid xs={12} alignItems="center">
  <canvas ref={photoRef} style={{ display: 'none' }}></canvas>
    
  </Grid>
  <Grid xs={4} alignItems="center">
  </Grid>
  <Grid container xs={4} justifyContent="center"  alignItems="center" >
  <CollectionsIcon fontSize="large" onClick={closePhoto}></CollectionsIcon>
  {/* <button onClick={closePhoto}>Galeria</button> */}
  </Grid>
  <Grid xs={4} alignItems="center">
  </Grid>
  <Grid xs={4} alignItems="center">
  </Grid>
  <Grid container xs={4} justifyContent="center"  alignItems="center" >
  Galeria
  {/* <button onClick={closePhoto}>Galeria</button> */}
  </Grid>
  <Grid xs={4} alignItems="center">
  </Grid>
  {/* <div className="camera">
      <div className="video-container">
        <video ref={videoRef}></video>
        <button onClick={takePhoto}>Foto</button>
        <button onClick={switchCamera}>
          Switch to {facingMode === 'user' ? 'Trasera' : 'frontal'} 
        </button>
      </div>
      <div className={"result " + (hasPhoto ? 'hasPhoto' : '')}>
        <canvas ref={photoRef}></canvas>
        <button onClick={closePhoto}>Borrar</button>
      </div>
    </div> */}
</Grid>
</div>
</>) : (<>
  
  <audio ref={audioRef} src={locpLay} preload="auto"/>
    <Navbar className="navmusic" locpLay={audioRef} imgVolumenShow={imgVolumenShow} musicaText={<a href='/#/MenuInicial'><img src={hamburg}></img></a>}/>
    <div className="contenedorCamara">
    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
  <Grid xs={4} alignItems="center">
  </Grid>
  <Grid container xs={4} justifyContent="center"  alignItems="center">
        {/* <item><FlipCameraAndroidIcon onClick={switchCamera}></FlipCameraAndroidIcon></item> */}
        <item>💩</item>
  </Grid>
  <Grid xs={4} alignItems="center">
  </Grid>
  <Grid xs={12} alignItems="center">
  
  </Grid>
  <Grid xs={12} alignItems="center">
  
  </Grid>
  <Grid xs={12} alignItems="center">
  
  </Grid>
  <Grid xs={12} alignItems="center">
  <input type="file" onChange={handleFileChange} />
  </Grid>
  <Grid xs={12} alignItems="center">
  
  </Grid>
  <Grid xs={12} alignItems="center">
  
  </Grid>
  <Grid xs={12} alignItems="center">
  
  </Grid>
  <Grid xs={4} alignItems="center">
  </Grid>
  <Grid container xs={4} justifyContent="center"  alignItems="center" >
    <CameraIcon fontSize="large" onClick={handleUpload}></CameraIcon>
  </Grid>
  <Grid xs={4} alignItems="center">
  </Grid>
  <Grid xs={4} alignItems="center">
  </Grid>
  <Grid container xs={4} justifyContent="center"  alignItems="center" >
    Guardar
  </Grid>
  <Grid xs={4} alignItems="center">
  </Grid>
  <Grid xs={12} alignItems="center">
  
  </Grid>
  <Grid xs={4} alignItems="center">
  </Grid>
  <Grid container xs={4} justifyContent="center"  alignItems="center" >
  </Grid>
  <Grid xs={4} alignItems="center">
  </Grid>
  <Grid xs={12} alignItems="center">
  </Grid>
  <Grid xs={12} alignItems="center">
  </Grid>
  <Grid xs={12} alignItems="center">
  </Grid>
  <Grid xs={12} alignItems="center">
  </Grid>
  <Grid xs={12} alignItems="center">
  </Grid>
  <Grid xs={12} alignItems="center">
  </Grid>
  <Grid xs={12} alignItems="center">
  </Grid>
  <Grid xs={12} alignItems="center">
  </Grid>
  <Grid xs={4} alignItems="center">
  </Grid>
  <Grid container xs={4} justifyContent="center"  alignItems="center" >
  <CollectionsIcon fontSize="large" onClick={closePhoto}></CollectionsIcon>
  </Grid>
  <Grid xs={4} alignItems="center">
  </Grid>
  <Grid xs={4} alignItems="center">
  </Grid>
  <Grid container xs={4} justifyContent="center"  alignItems="center" >
  Galeria
  {/* <button onClick={closePhoto}>Galeria</button> */}
  </Grid>
  <Grid xs={4} alignItems="center">
  </Grid>
  <Grid xs={12} alignItems="center">
  
  </Grid>
  
</Grid>
</div>

</>)

    
  );
};

export default Camera;
