// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../../imagenes/svg/FONDOLOBY.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fondoEnteroLoby {
    background-color: #f7f0ac;
}
.fondo1Loby {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: 100%;
    background-repeat:repeat-x;
}
.IMGFONDOLoby{
    width: 100%;
    position: relative;
    vertical-align: middle;
}

/* CRONOMETRO ESTILOS */

.cronometroLoby {
    background: rgba(3, 1, 1, 0.6);
    font-family: 'Courier New', monospace;
    font-size: 20px;
    /* background-color: #15ad22; */
    padding: 10px;
    color: #f5f4ef;
    border: 1px white;
    text-align: center;
    border-radius: 5px;
   }
   
   .cronometroLoby .digitoLoby {
    display: inline-block;
    /* width: 30px; */
    margin: 0 10px;
   }

   .mensajeLoby{
    /* font-family: "Comic Sans MS", cursive; */
    font-size: 1.5em;
    text-align: center;
    color: #f8eeee;
    padding: 10px;
    margin-top: 1em;
    border: 1px white;
    background: rgba(3, 1, 1, 0.6);

   }

   .container_Row_Col{
    padding: 1em;
   }
   .imagenAvatar{
    width: 90%;
   }`, "",{"version":3,"sources":["webpack://./src/pages/Lobi/Lobi.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;AACA;IACI,yDAA2D;IAC3D,qBAAqB;IACrB,0BAA0B;AAC9B;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA,uBAAuB;;AAEvB;IACI,8BAA8B;IAC9B,qCAAqC;IACrC,eAAe;IACf,+BAA+B;IAC/B,aAAa;IACb,cAAc;IACd,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;GACnB;;GAEA;IACC,qBAAqB;IACrB,iBAAiB;IACjB,cAAc;GACf;;GAEA;IACC,2CAA2C;IAC3C,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;IACd,aAAa;IACb,eAAe;IACf,iBAAiB;IACjB,8BAA8B;;GAE/B;;GAEA;IACC,YAAY;GACb;GACA;IACC,UAAU;GACX","sourcesContent":[".fondoEnteroLoby {\n    background-color: #f7f0ac;\n}\n.fondo1Loby {\n    background-image: url('./../../imagenes/svg/FONDOLOBY.svg');\n    background-size: 100%;\n    background-repeat:repeat-x;\n}\n.IMGFONDOLoby{\n    width: 100%;\n    position: relative;\n    vertical-align: middle;\n}\n\n/* CRONOMETRO ESTILOS */\n\n.cronometroLoby {\n    background: rgba(3, 1, 1, 0.6);\n    font-family: 'Courier New', monospace;\n    font-size: 20px;\n    /* background-color: #15ad22; */\n    padding: 10px;\n    color: #f5f4ef;\n    border: 1px white;\n    text-align: center;\n    border-radius: 5px;\n   }\n   \n   .cronometroLoby .digitoLoby {\n    display: inline-block;\n    /* width: 30px; */\n    margin: 0 10px;\n   }\n\n   .mensajeLoby{\n    /* font-family: \"Comic Sans MS\", cursive; */\n    font-size: 1.5em;\n    text-align: center;\n    color: #f8eeee;\n    padding: 10px;\n    margin-top: 1em;\n    border: 1px white;\n    background: rgba(3, 1, 1, 0.6);\n\n   }\n\n   .container_Row_Col{\n    padding: 1em;\n   }\n   .imagenAvatar{\n    width: 90%;\n   }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
