import React, { useRef } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import SignatureCanvas from 'react-signature-canvas';

import './Registro.css';

const Firma = ({navigate, IdiomaTextActual}) => {
    const signatureRef = useRef();

    const handleClear = () => {
      signatureRef.current.clear();
    };
  
    const handleSubmit = () => {
      const canvas = signatureRef.current.getTrimmedCanvas(); // Obtén el lienzo recortado
      const imageUrl = canvas.toDataURL(); // Obtén la imagen en formato base64
  
      // Aquí puedes hacer lo que quieras con la imagen, como enviarla a un servidor
      fetch('https://bbdd.adrianymarianna.com/invitados/save-image', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ image: imageUrl, nombre: JSON.parse(localStorage.getItem("invitado"))._id })
      })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error(error));


      //GUARDARLA EN EL SERVIDOR DESDE NODE

      Swal.close(); // Cierra el modal de SweetAlert
      
    };
  
    return (
      <div className="FirmaDiv">
        <SignatureCanvas
          ref={signatureRef}
          penColor="black"
          canvasProps={{ width: 400, height: 200, className: 'sigCanvas' }}
        />
        <button className="form__buttonClear" onClick={handleClear}>{IdiomaTextActual.buttonfirmalimpiar}</button>
        <button className="form__button" onClick={handleSubmit}>{IdiomaTextActual.buttonfirmaguardar}</button>
      </div>
    );
};

export default Firma;