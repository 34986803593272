import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { API } from "../../services/api";
import './panelUser.css';

const PanelUser = () => {
  const [invitados, setInvitados] = useState([]);
  const [avatares, setAvatares] = useState([]);
  const [idiomaTextActual, setIdiomaTextActual] = useState({});
  const [invitadosAll, setInvitadosAll] = useState([]);

  useEffect(() => {
        const getInvitados = async () => {
          try {
            const res = await API.get("/invitados/contadorInvitado");
            console.log("respuesta", res.data.invitados);
            setInvitados(res.data.invitados);
          } catch (error) {
            console.error("Error fetching invitados", error);
          }
        };
        getInvitados();
  }, []);


  useEffect(() => {
        const getAvatares = async () => {
          try {
            const res = await API.get("/avatares");
            console.log("respuestaaVATAR", res.data.Avatares);
            setAvatares(res.data.Avatares)

            
          } catch (error) {
            console.error("Error fetching invitados", error);
          }
    };
    getAvatares();
  }, []);


  useEffect(() => {
    const getInvitadosAll = async () => {
      try {
        const res = await API.get("/invitados");
        console.log("respuestaasetInvitadosAll", res.data.invitados);
        setInvitadosAll(res.data.invitados)

        
      } catch (error) {
        console.error("Error fetching invitados", error);
      }
};
getInvitadosAll();
}, []);



  return (
   
    <div className="DivAdmin">
       <h1 className="h1admin">Lista de Invitados</h1>
      <table className="tableAdmin">
        <thead>
          <tr>
            <th className="thadmin">Nombre</th>
            <th className="thadmin">Acciones</th>
            <th className="thadmin">List. Invitados</th>
          </tr>
        </thead>
        <tbody className="tbodyadmin">


          {invitados.map((invitado, index) => (
            <tr key={index}>
              <td className="tdAdmin">{invitado.count}</td>
              <td className="tdAdmin">
                {/* Renderiza la imagen correspondiente al avatar del invitado */}
                {(() => {

                  const avatar = avatares.find(avatar => avatar._id === invitado.idavatar);
                  return avatar ? <img className="imagen" src={require(`./../../imagenes/svg/${avatar.imagen1}`)} alt={avatar.name} /> : null;

                })()}
              </td>
              <td className="tdAdmin">{
                invitadosAll.map((InvitadoUniAll, index) => (
                <li key={index}>
                {InvitadoUniAll.id_avatares._id === invitado.idavatar ? (InvitadoUniAll.nombreCompleto) : ""}
                </li>
  

              )) }
          </td>

            </tr>



          ))}
<tr>

          </tr>
        </tbody>
      </table>
    </div>

  );
};

export default PanelUser;
