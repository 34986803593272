import './App.css';
import React, {useState, useEffect} from 'react'
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import QRCodeWithLogo from './pages/qrcode/Qrcode';
import QRCodeWithLogoBolsa from './pages/qrcode/QrcodeBolsas';
import QRindex from './pages/qrcode/QRindex';
import Menu from './pages/Menu/Menu';

import Registro from './pages/Registro/Registro';
import RegistroDirecto from './pages/Registro/RegistroDirecto';
import Pruebas from './Pruebas/Pruebas';
import Loby from './pages/Lobi/Lobi';

import Hotel from './pages/Hotel/Hotel2';
import BuscarHotel from './pages/Hotel/BuscarHotel';

import MenuInicial from './pages/MenuInicial/MenuInicial';
import MenuInicialLogin from './pages/MenuInicial/MenuInicialLogin';
import MenuInicialLoginfase3 from './pages/MenuInicial/MenuInicialLoginfase3';

import PANEL from './pages/ADMIN/panelUser.jsx';
import PANELMOVIL from './pages/ADMIN/panelUserMovil.jsx';
import PANELAVATARINVITADOS from './pages/ADMIN/avataresInvitados.jsx';

import SPOTY from './pages/SPOTY/BuscarCancion.jsx';

import Planing from './pages/CANVAS/PLANING.jsx';

import CardNovia from './pages/CARD/FotosNovia.jsx';
import CardNovio from './pages/CARD/FotosNovio.jsx';
import CardCamara from './pages/CARD/FotosCamara.jsx';
import Fotos from './pages/FOTOSUP/Fotos.jsx';
import InfoUtil from './pages/InfoUtil/InfoUtil.jsx';
import InfoUsuario from './pages/ADMIN/InfoPerfil.jsx';

/* import GaleriaDesk from './pages/GaleriaDesk/GaleriaDesk.jsx'; */
import GaleriaDesk from './pages/GaleriaDesk/GaleriaDeskLighbox.jsx';
function App() {

  const url = "http://nuestraboda.adrianymarianna.com";
  const url2 = "http://nuestraboda.adrianymarianna.com/#/galeriaBoda";
  const [isDesktop, setisDesktop] = useState(window.matchMedia('(min-width: 768px)').matches);
  const [isTablet, setisTablet] = useState(window.matchMedia('(min-width: 576px)').matches);
  return (
    
    <div>
      <Router>
        <Routes>
          {/* <Route path='/login/:user' setLoginStatus={setLoginStatus} element={<Home />} /> */}
          <Route path='/' element={isDesktop ? (<QRindex />) : (<Home  />)} />
          <Route path='/menu/:id_inv' element={isDesktop ? (<QRindex />) : (<Menu  />)} />
          <Route path='/inv/:id_inv' element={isDesktop ? (<QRindex />) : (<Home  />)} />    
          <Route path='/registro' element={isDesktop ? (<QRindex />) : (<Registro  />)} />
          <Route path='/registroDirecto' element={isDesktop ? (<QRindex />) : (<RegistroDirecto/>)} />
          <Route path='/loby' element={isDesktop ? (<QRindex />) : (<Loby  />)} />
          <Route path='/menuInicial' element={isDesktop ? (<QRindex />) : (<MenuInicial  />)} />
          <Route path='/menuInicialLogin/:id_inv' element={isDesktop ? (<QRindex />) : (<MenuInicialLogin  />)} />
          <Route path='/menuInicialLoginfase3/:id_inv' element={isDesktop ? (<QRindex />) : (<MenuInicialLoginfase3/>)} />
          <Route path='/Hotel' element={isDesktop ? (<QRindex />) : (<Hotel  />)} />
          <Route path='/HotelBuscar' element={isDesktop ? (<QRindex />) : (<BuscarHotel  />)} />
          <Route path='/Panel' element={isDesktop ? (<PANEL />) : (<PANELMOVIL  />)} />
          <Route path='/PANELAVATARINVITADOS' element={isDesktop ? (<PANELAVATARINVITADOS />) : (<PANELAVATARINVITADOS  />)} />
          <Route path='/pruebas' element={<Pruebas  />} />
          <Route path='/QR' element={<QRCodeWithLogo url={url} />} />
          <Route path='/QRbolsas/:id_inv' element={<QRCodeWithLogoBolsa url={url2} />} />
          <Route path='/spoty' element={isDesktop ? (<QRindex />) : (<SPOTY />)} />
          <Route path='/planwed' element={isDesktop ? (<QRindex />) : (<Planing />)} />
          <Route path='/cardNovia' element={isDesktop ? (<QRindex />) : (<CardNovia />)} />
          <Route path='/cardNovio' element={isDesktop ? (<QRindex />) : (<CardNovio />)} />
          <Route path='/Fotos' element={isDesktop ? (<QRindex />) : (<Fotos />)} />
          <Route path='/GaleriaCamara' element={isDesktop ? (<QRindex />) : (<CardCamara />)} />
          <Route path='/InfoUtil' element={isDesktop ? (<QRindex />) : (<InfoUtil/>)} />
          <Route path='/galeriaBoda/:id_inv' element={isDesktop ? (<QRindex />) : (<InfoUsuario/>)} />
          <Route path='/GaleriaDesk/' element={isDesktop ? (<GaleriaDesk />) : (<GaleriaDesk/>)} />
        </Routes>  
      </Router>
    </div>
  );
}

export default App;
