// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qrcodediv{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.texto{
    padding: 2em;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/qrcode/QRindex.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".qrcodediv{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n.texto{\n    padding: 2em;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
