import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { API } from "../../services/api";
import './panelUser.css';

const PanelUser = () => {
  const [invitados, setInvitados] = useState([]);
  const [idiomaTextActual, setIdiomaTextActual] = useState({});
  const [contador, setcontador] = useState(0);
  
  useEffect(() => {
    const fetchData = async () => {
      if (true)/* (localStorage.getItem("invitado")) */ {
        const getInvitados = async () => {
          try {
            if(contador == 0){
              const res = await API.get("/invitados");
            console.log("respuesta", res);
            setInvitados(res.data.invitados);
            setcontador(1);
            }
          } catch (error) {
            console.error("Error fetching invitados", error);
          }
        };

        if (localStorage.getItem("textos")) {
          const filtrarPorIdioma = (elemento) => 
            elemento.idioma === JSON.parse(localStorage.getItem("invitado")).idioma;
          const arrayTextos = JSON.parse(localStorage.getItem('textos'));
          const resultado = arrayTextos.filter(filtrarPorIdioma);
          setIdiomaTextActual(resultado[0]);
        }

        getInvitados();
      } else {
        Swal.fire({
          icon: 'error',
          title: idiomaTextActual.titleswalsininv,
          text: idiomaTextActual.textswalsininv,
          confirmButtonText: idiomaTextActual.buttonswalsininv
        }).then(result => {
          if (result.isConfirmed) {
            window.location.replace('https://www.google.com/search?q=GNET+-+Grupalia+Internet+S.A.');
          }
        });
      }
    };

    fetchData();
  }, [idiomaTextActual]);

  const handleCopy = (text) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text).then(() => {
        Swal.fire({
          icon: 'success',
          title: '¡Copiado!',
          text: 'La invitación ha sido copiada al portapapeles.',
          timer: 1500,
          showConfirmButton: false
        });
      }).catch(err => {
        console.error('Error al copiar: ', err);
      });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        Swal.fire({
          icon: 'success',
          title: '¡Copiado!',
          text: 'La invitación ha sido copiada al portapapeles.',
          timer: 1500,
          showConfirmButton: false
        });
      } catch (err) {
        console.error('Error al copiar: ', err);
      }
      document.body.removeChild(textArea);
    }
  };

  return (
    <div className="DivAdmin">
      <h1 className="h1admin">Lista de Invitados</h1>
      <table className="tableAdmin">
        <thead>
          <tr>
            <th className="thadmin">Nombre</th>
            <th className="thadmin">Acciones</th>
          </tr>
        </thead>
        <tbody className="tbodyadmin">
          {invitados.map((invitado, index) => (
            <tr key={index}>
              <td className="tdAdmin">{invitado.nombreCompleto}</td>  
              <td className="tdAdmin">
                <button className="butadmin" onClick={() => handleCopy("https://nuestraboda.adrianymarianna.com/#/inv/"+invitado._id)}>
                  ♿
                </button>
                <button  className="butadmin"  onClick={() => handleCopy("https://nuestraboda.adrianymarianna.com/#/menuIniciallogin/"+invitado._id)}>
                  ✡️
                </button>
                <button  className="butadmin"  onClick={() => handleCopy(invitado._id)}>
                  ❤️
                </button>
                <button  className="butadmin"  onClick={() => handleCopy("https://nuestraboda.adrianymarianna.com/#/QRbolsas/"+invitado._id)}>
                  💩
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PanelUser;
