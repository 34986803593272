import React, {useState, useEffect, useRef} from 'react'
import { useParams, useNavigate } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';



import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

import ZARAGOZA from './../../imagenes/svg/Zaragoza.svg';
import GRANADA from './../../imagenes/svg/Granada.svg';
import HOTEL from './../../imagenes/svg/Hotel.svg';
import FOTOS from './../../imagenes/svg/Fotos.svg';
import MENUCOMIDA from './../../imagenes/svg/MenuComida.svg';
import MUSICA from './../../imagenes/svg/Musica.svg';
import BUSITINERARIO from './../../imagenes/svg/ISLAITINERARIO.svg';
import REGISTRO from './../../imagenes/svg/IslaRegistro.svg';
import ISLAINFO from './../../imagenes/svg/ISLAINFO.svg';
import ISLALOBY from './../../imagenes/svg/ISLALOBY.svg';

import { API } from "../../services/api";


import NavInicial from "./../../carga/NavInicial"


import './MenuInicial.css';

import Navbar from './../../pages/Navbar/Navbar';
import audiomp3 from './../../audio/outsideintro.mp3';


import hamburg from './../../imagenes/bar-de-hamburguesas.png';

const Registro = () => {



  const [cargaVideoShow, setcargaVideoShow] = useState(true);
  const cargaVideoON = (valor) =>{
      setcargaVideoShow(valor);
  } 

  const audioRef = useRef(null);
  const [locpLay, setlocpLay] = useState(audiomp3);
  const [imgVolumenShow, setimgVolumenShow] = useState(false);
    
    /* const navigate = useNavigate(); */

    //TEXTOS INCIIALES
    const [IdiomaTextActual, setIdiomaTextActual] = useState({
      "_id": "6599cac40db07abe9ffb9e96",
			"idioma": "espana",
			"imgNoscasamos": "noscasamos.png",
			"textosCarta": "Queridos amigos, ¡la hora ha llegado!\nAdrián y Majka, por fin casados.\nSerá en Lupiana, el 20 de septiembre,\nfirmando que su amor dure para siempre.\n\nNo falten a este día tan especial,\ncon baile, comida, ¡será genial!\nOs esperamos con cariño y emoción,\npara ser testigos de esta unión.",
			"textosCheckCarta": "Pasar a aceptar las condiciones de asistencia",
			"textoCheck1": "Acepto bailar como si nadie estuviera mirando, incluso si todos lo están haciendo, aunque mis movimientos se asemejen más a un pingüino en patinaje sobre hielo",
			"textoCheck2": "Estoy de acuerdo en reírme hasta que me duela la barriga, aunque mi risa suene como una mezcla entre una hiena y un pato",
			"textoCheck3": "Acepto que las fotos embarazosas son una parte obligatoria de la celebración, y me uniré a la diversión sin quejarme, incluso si eso implica usar accesorios ridículos para fotos grupales",
			"textoCheck4": "Prometo no contar chistes malos, a menos que sean tan malos que se vuelvan buenos por accidente",
			"textoCheck5": "Acepto que la pista de baile será mi gimnasio y bailaré hasta quemar más calorías de las consumidas en la cena",
			"textoCheck6": "Acepto tomarme un chupito de Soplica para no hacerle un feo a la novia",
			"textoCheck7": "Estoy dispuesto a aplaudir, gritar \"vivan los novios\" y vitorear exageradamente, animando a los novios y demás invitados como si estuviera en un estadio deportivo",
			"textoCheck8": "Estoy de acuerdo en que los abrazos y besos son obligatorios, y cualquier intento de resistirse será castigado con abrazos y besos aún más fuertes",
			"textoCheck9": "Prometo no rechazar ninguna oferta de comida, postre o delicioso manjar que se cruce en mi camino durante la celebración",
			"textoCheck10": "Acepto que las risas y los recuerdos compartidos superarán cualquier intento de mantener la dignidad intacta",
			"textoCheck11": "Me comprometo a confirmar mi asistencia en fecha. Si no, me llevaré una silla y un sándwich",
			"textoFailCheckTitle": "Acepta, TODAS las condiciones.",
			"textoFailCheck": "Aqui, hemos venido a jugar.",
			"textoSucesCheckTitle": "Gracias por aceptar las condiciones",
			"textoSucesCheck": "Ahora solo queda que elijas tu avatar.",
			"createdAt": "2024-01-06T21:48:52.020Z",
			"updatedAt": "2024-03-31T14:31:02.328Z",
			"__v": 0,
			"cronometroText": "Faltan",
			"imagenLosdosText": "adrianymajkaSUN.png",
			"cronometroTextSegundos": "Segundos",
			"musicaText": "Activa el sonido",
			"textoTyC": "Condiciones de asistencia",
			"textoCarga": "Tenemos algo importante que deciros...\n¡Nos casamos!\n",
			"imagenSello": "sellocartaLogo.svg",
			"imagenSelloM": "sellocartaLogoON.svg",
			"buttonfirmaguardar": "Guardar",
			"buttonfirmalimpiar": "Limpiar",
			"buttonswalfirmacancel": "Cerrar",
			"cargamusicatext": "¡Sube el volumen!",
			"iconoContinuar": "BTNMUSICESP.png",
			"iconoContinuarsvg": "BTNMUSICESP.svg",
			"inputtextarea": "¿Se te ocurre algún otro avatar? ¡Dinos cuál! Recuerda que la temática es: flores, plantas…",
			"textformlargo": "Todavía estamos ultimando algunos detalles de la boda. Por ello, necesitamos que nos confirmes tus datos de contacto, para poder avisarte de nuevas noticias sobre el evento.",
			"textoinput1": "Móvil:",
			"textoinput2": "Email:",
			"textswalsininv": "Debes acceder por el enlace que te hemos enviado. Lo siento.",
			"titleswalfirma": "Necesitamos una firmita por aqui…",
			"titleswalsininv": "No se quién eres\n\n😷\n",
			"buttonconfirmacion": "Confirmar asistencia",
			"texttemporizador": "¿Cuánto falta para el gran día?",
			"texttemporizadorsegundos": "Tan solo faltan",
			"textlobyfinal": "Os iremos avisando tan pronto tengamos todos los detalles del evento. Mientras tanto, por favor, esperad aquí junto al resto de invitados.",
			"swalnoregistro": "Esperamos que puedas venir",
			"textoMorfeo": "Tomas la píldora azul... la historia termina, te despiertas en tu cama y crees lo que quieras creer. Tomas la píldora roja... te quedas en el País de las Maravillas, y te enseño lo profunda que es la madriguera del conejo",
			"videoRick": "rickadmortyvideo.mp4",
      "titlealertseleccion": "Toca elegir el avatar.",
	"mensajealertseleccion": "Recuerda que tienes varios para elegir, tomate tu tiempo.",
	"botonalertseleccion": "Aceptar",
  "botonalertavatar": "Lo quiero!",
  "titlealertavatar": "¿Quieres este avatar?"
    });
//SACO AVATARES
      const [Avatares, setAvatares] = useState([
        {
          "_id": "65786b219653cdc512ccdb10",
          "name": "dwight",
          "imagen1": "dwight.svg",
          "imagen2": "dwight.png",
          "createdAt": "2023-12-12T14:16:01.327Z",
          "updatedAt": "2023-12-12T14:16:01.327Z",
          "__v": 0
        },
        {
          "_id": "65786b259653cdc512ccdb12",
          "name": "firasolZombie",
          "imagen1": "firasolZombie.svg",
          "imagen2": "firasolZombie.png",
          "createdAt": "2023-12-12T14:16:05.114Z",
          "updatedAt": "2023-12-12T14:16:05.114Z",
          "__v": 0
        }]);

  useEffect(() => {
    if(localStorage.getItem("invitado")){
      const getAvataresId = async () => {
        const userInv =  await API.get("/avatares").then((res) => {
          console.log("respuesta",res);
          setAvatares(res.data.Avatares);
        })
      };
      if(localStorage.getItem("textos")){

        const filtrarPorIdioma = (elemento) => elemento.idioma === JSON.parse(localStorage.getItem("invitado")).idioma;
        const arrayTextos = JSON.parse(localStorage.getItem('textos'));
        const resultado = arrayTextos.filter(filtrarPorIdioma);
        console.log("texto de bbdd: ", resultado[0]);
        setIdiomaTextActual(resultado[0]);
      }

      getAvataresId();
    }else{
      Swal.fire({
        icon: 'error',
        title: IdiomaTextActual.titleswalsininv,
        text: IdiomaTextActual.textswalsininv,
        confirmButtonText: IdiomaTextActual.buttonswalsininv
      }).then(result => {
        if (result.isConfirmed) {
          window.location.replace('https://www.google.com/search?q=GNET+-+Grupalia+Internet+S.A.');
        }
     })
      
    }
    
  }, []);
  
  //FORMULARIO INVITACION

  const [mobileNumber, setMobileNumber] = useState(JSON.parse(localStorage.getItem("invitado")).nmovil);
  const [email, setEmail] = useState(JSON.parse(localStorage.getItem("invitado")).email);
  const [message, setMessage] = useState(JSON.parse(localStorage.getItem("invitado")).mensaje);
  const [idavatar, setidavatar] = useState('');
  const [idinvitado, seidinvitado] = useState(JSON.parse(localStorage.getItem("invitado"))._id);
  console.log( "datos guardados del inv:", JSON.parse(localStorage.getItem("invitado")));
 
 

  const [SelectAvatar, setSelectAvatar] = useState(true);



  const [index, setIndex] = useState(0);
  

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
   
  };


  
      return (
      <div className="fondoIslas">
      <div className="divcar">
      <Carousel /*  data-bs-theme="dark" nextIcon={<FaArrowAltCircleRight size={30} />}  */
        /* prevIcon="" */
        nextLabel=""
        prevLabel=""
        activeIndex={index} 
        onSelect={handleSelect}>
          <Carousel.Item>
        <img
          className="imgCarrusel"
          src={ISLAINFO}
          alt="First slide"
        />
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="imgCarrusel"
          src={HOTEL}
          alt="First slide"
        />
      </Carousel.Item> 
          <Carousel.Item>
        <img
          className="imgCarrusel"
          src={BUSITINERARIO}
          alt="First slide"
        />
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="imgCarrusel"
          src={MENUCOMIDA}
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="imgCarrusel"
          src={FOTOS}
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="imgCarrusel"
          src={MUSICA}
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="imgCarrusel"
          src={GRANADA}
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="imgCarrusel"
          src={ZARAGOZA}
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="imgCarrusel"
          src={REGISTRO}
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="imgCarrusel"
          src={ISLALOBY}
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>
      {cargaVideoShow && <NavInicial IdiomaTextActual={IdiomaTextActual} quitarCarga={(cargaVideoON)} IndexSlide={index} ></NavInicial>}
      </div>
      </div>
      );
    }

  export default Registro