import React, { useState, useRef } from 'react';

import videoRick from '../video/rickadmortyvideo.mp4';
import Swal from 'sweetalert2'

import morfeo from '../imagenes/svg/morfeo.svg';
import portalRick from '../imagenes/portalRick.png';


import "./cargaVideo.css"

const VideoBackground = ({ quitarCarga, IdiomaTextActual, locpLay}) => {

  console.log("idiomas carga", IdiomaTextActual);
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [lastVideo, setlastVideo] = useState(false);
  const [timeload, settimeload] = useState(true); //desactivado por iphone. activarlo con valor false

  const handleVideoLoadedData = () => {
    console.log('El video se ha cargado correctamente');
    settimeload(true);
  };

  

  const togglePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
      //aqui los tiempos para quitar video
      setTimeout(() => setlastVideo(true), 59000);
      /* setTimeout(() => setlastVideo(true), 1000); */
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };
  const esperaInvitacion = () => {
    Swal.fire({
      icon: 'error',
      title: '🥲',
      text: IdiomaTextActual.swalnoregistro,
      confirmButtonText: '<i class="fas fa-check"></i> Aceptar',
            confirmButtonColor: '#2ecc71',
            confirmButtonAriaLabel: 'OK'
    }).then(result => {
      if (result.isConfirmed) {
        window.location.replace('https://www.google.com/search?q=GNET+-+Grupalia+Internet+S.A.');
      }
   })


  };
  const continuaInvitacion = () => {
    
    if (locpLay.current) {
      locpLay.current.play();
      quitarCarga(false);
  }

  };

  return (
    <footer className='CargaNCasamosDiv'>
    {lastVideo ?
    <div className='centrarMorf'>
       {/* <img className="portalRick" src={portalRick}></img> */}
      <p className="letrasMorf" >{IdiomaTextActual.textoMorfeo}</p>
      <div className="contenedorMorfeo" >
        
        <img className="MorfeoImg" src={morfeo}></img>
        <button className="MorfeoImgazul" onClick={esperaInvitacion}></button>
        <button className="MorfeoImgrojo" onClick={continuaInvitacion}></button>
      </div>
    </div>  
     : 
    <div className="video-background">
      <video ref={videoRef} /* autoPlay */ /* onLoadedData={handleVideoLoadedData}  */mute className="video-background__video">
{IdiomaTextActual.videoRick && <source src={require(`../video/${IdiomaTextActual.videoRick}`)} type="video/mp4" />}
        Tu navegador no soporta la reproducción de video.
      </video>
      <div className="video-background__content">
        {timeload ? <h1 className="buttonVideo" onClick={togglePlayPause}>{isPlaying ? '' : '▶️'}</h1> : <h1>⌛</h1>}
        {/* Aquí puedes agregar cualquier contenido que desees superponer sobre el video */}
      </div>
    </div>
}
    </footer>
  );
};

export default VideoBackground;
