import React, { useEffect, useRef, useState } from 'react';

const GifOnView = ({ src, alt, className }) => {
  const gifRef = useRef();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.1, // Trigger when 10% of the gif is visible
      }
    );

    if (gifRef.current) {
      observer.observe(gifRef.current);
    }

    return () => {
      if (gifRef.current) {
        observer.unobserve(gifRef.current);
      }
    };
  }, []);

  return (
    <img
      ref={gifRef}
      src={src}
      alt={alt}
      className={className}
    />
  );
};

export default GifOnView;
