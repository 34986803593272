import React, {useRef, useState, useEffect} from 'react'
import logoAlas from './../../imagenes/svg/logoAlas.svg';
import logoContenido from './../../imagenes/svg/logoContenido.svg';
import './MenuInicialLogin.css';
import { useParams, useNavigate } from "react-router-dom";

import { API } from "./../../services/api";


const MenuLogin = () => {
  const navigate = useNavigate();
  const { id_inv } = useParams();
  const audioRef = useRef(null);



 


  
  const [visibilidadLogo, setvisibilidadLogo] = useState(false);
const [UserId, setUserId] = useState({});
const [IdiomaTextActual, setIdiomaTextActual] = useState({
  "_id": "6599cac40db07abe9ffb9e96",
			"idioma": "espana",
			"cargamusicatext": "¡Sube el volumen!",
			"iconoContinuar": "BTNMUSICESP.png",
			"iconoContinuarsvg": "BTNMUSICESP.svg",
			"imgNoscasamos": "noscasamos.png",
			"titleswalsininv": "No se quién eres\n\n😷\n",
			"textswalsininv": "Debes acceder por el enlace que te hemos enviado. Lo siento.",
			"buttonswalsininv": "¡OK!😒",
			"titleswalfirma": "Necesitamos una firmita por aqui…",
			"buttonswalfirmacancel": "Cerrar",
			"buttonfirmalimpiar": "Limpiar",
			"buttonfirmaguardar": "Guardar",
			"textformlargo": "Todavía estamos ultimando algunos detalles de la boda. Por ello, necesitamos que nos confirmes tus datos de contacto, para poder avisarte de nuevas noticias sobre el evento.",
			"textoinput1": "Móvil:",
			"textoinput2": "Email:",
			"inputtextarea": "¿Se te ocurre algún otro avatar? ¡Dinos cuál! Recuerda que la temática es: flores, plantas…",
			"buttonconfirmacion": "Confirmar asistencia",
			"textosCarta": "Queridos amigos, ¡la hora ha llegado!\nAdrián y Majka, por fin casados.\nSerá en Lupiana, el 20 de septiembre,\nfirmando que su amor dure para siempre.\n\nNo falten a este día tan especial,\ncon baile, comida, ¡será genial!\nOs esperamos con cariño y emoción,\npara ser testigos de esta unión.",
			"textosCheckCarta": "Acepto las condiciones de la invitación",
			"textoCheck1": "Acepto bailar como si nadie estuviera mirando, incluso si todos lo están haciendo, aunque mis movimientos se asemejen más a un pingüino en patinaje sobre hielo",
			"textoCheck2": "Estoy de acuerdo en reírme hasta que me duela la barriga, aunque mi risa suene como una mezcla entre una hiena y un pato",
			"textoCheck3": "Acepto que las fotos embarazosas son una parte obligatoria de la celebración, y me uniré a la diversión sin quejarme, incluso si eso implica usar accesorios ridículos para fotos grupales",
			"textoCheck4": "Prometo no contar chistes malos, a menos que sean tan malos que se vuelvan buenos por accidente",
			"textoCheck5": "Acepto que la pista de baile será mi gimnasio y bailaré hasta quemar más calorías de las consumidas en la cena",
			"textoCheck6": "Acepto tomarme un chupito de Soplica para no hacerle un feo a la novia",
			"textoCheck7": "Estoy dispuesto a aplaudir, gritar \"vivan los novios\" y vitorear exageradamente, animando a los novios y demás invitados como si estuviera en un estadio deportivo",
			"textoCheck8": "Estoy de acuerdo en que los abrazos y besos son obligatorios, y cualquier intento de resistirse será castigado con abrazos y besos aún más fuertes",
			"textoCheck9": "Prometo no rechazar ninguna oferta de comida, postre o delicioso manjar que se cruce en mi camino durante la celebración",
			"textoCheck10": "Acepto que las risas y los recuerdos compartidos superarán cualquier intento de mantener la dignidad intacta",
			"textoCheck11": "Me comprometo a confirmar mi asistencia en fecha. Si no, me llevaré una silla y un sándwich",
			"textoFailCheckTitle": "Acepta, TODAS las condiciones.",
			"textoFailCheck": "Aqui, hemos venido a jugar.",
			"textoSucesCheckTitle": "Gracias por aceptar las condiciones",
			"textoSucesCheck": "Ahora solo queda que elijas tu avatar.",
			"createdAt": "2024-01-06T21:48:52.020Z",
			"updatedAt": "2024-01-21T22:25:53.666Z",
			"__v": 0,
			"cronometroText": "Faltan",
			"imagenLosdosText": "adrianymajkaSUN.png",
			"cronometroTextSegundos": "Seg.",
			"musicaText": "Activa el sonido",
			"textoTyC": "Condiciones de asistencia",
			"textoCarga": "Tenemos algo importante que deciros...\n¡Nos casamos!\n",
			"imagenSello": "sellocartaLogo.svg",
			"imagenSelloM": "sellocartaLogoON.svg"
});

  useEffect(() => {
    const getUserId = async () => {
      const userInv =  await API.get("/invitados/"+ id_inv).then((res) => {
        console.log( "respuesta inv ",res.data.invitados);
        setUserId(res.data.invitados);
        
        localStorage.setItem("invitado", JSON.stringify(res.data.invitados));
        fetch('https://api.ipify.org?format=json')
    .then(response => response.json())
    .then(data => {
       console.log('IP:', data.ip);
       /* alert(data.ip); */
       const sesionUp = API.post("/sesion", {"ip": data.ip, "id_invitado": id_inv, "etapa": "menuinicial"}).then((res) => {
         /* console.log( "respuesta",res); */
         /* alert(res); */
         
       })
   
    });

      }).then(
        setTimeout(() => setvisibilidadLogo(true), 500)
      ).then(
        setTimeout(() => viajarMenuInicial(), 4500)
      )
    };
    getUserId();
    
  }, []);

  useEffect(() => {
    const getUserIdioma = async () => {
      
        const IdiomasText = await API.get("/textos/").then((res) => {
          
            localStorage.setItem("textos", JSON.stringify(res.data.Textos)); 
            
          console.log("idiomas", res.data.Textos);
        })
      };
   getUserIdioma();
  }, []);


//RELLENAR DATOS SESION

const viajarMenuInicial =  () => {
      
    fetch('https://api.ipify.org?format=json')
.then(response => response.json())
.then(data => {
   console.log('IP:', data.ip);
   /* alert(data.ip); */
   const sesionUp = API.post("/sesion", {"ip": data.ip, "id_invitado": id_inv}).then((res) => {
     /* console.log( "respuesta",res); */
     /* alert(res); */
     
   }).then(
    navigate("/menuInicial")
   )

});
//FIN SESION
  };



    useEffect(() => {
      if(localStorage.getItem("textos")){

        const filtrarPorIdioma = (elemento) => elemento.idioma === JSON.parse(localStorage.getItem("invitado")).idioma;
        const arrayTextos = JSON.parse(localStorage.getItem('textos'));
        const resultado = arrayTextos.filter(filtrarPorIdioma);

        setIdiomaTextActual(resultado[0]);
  
      }

 }, []);
    
    return (
      <div className="fondoLoginInicial">
      
      <img  className={visibilidadLogo ? "logoAlas rotate active" : "logoAlas rotate inactive"} src={logoAlas}></img>
      <img  className={visibilidadLogo ? "logoContenido active" : "logoContenido inactive"} src={logoContenido}></img>
      <p>Bienvenido {UserId.nombreCompleto}, nos encanta volverte a ver por aqui. </p>
    </div>
    );
  }

export default MenuLogin