import React from 'react';
import Camera from './Camera';


function Fotos() {
  return (
      <Camera />
  );
}

export default Fotos;
