import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { API } from "../../services/api";
import "./Menu.css";
import Form from 'react-bootstrap/Form'
import { useParams, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Figure from 'react-bootstrap/Figure';
import { Carousel } from 'react-bootstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import LUBINAPNG from './../../imagenes/lubina.png';
import SOLOMILLOPNG from './../../imagenes/solomillo.png';

import fondoCarta from './../../imagenes/imagenCarta.jpg';

import Navbar from './../../pages/Navbar/Navbar';
import audiomp3 from './../../audio/sonidonaturaleza.mp3';


import hamburg from './../../imagenes/bar-de-hamburguesas.png';
//STEP
const Menu = () => {
  const { id_inv } = useParams();
  const navigate = useNavigate();




  const audioRef = useRef(null);
  /* alert(extension); */
  if (id_inv == undefined){
    const MySwal = withReactContent(Swal)
    MySwal.fire({
      icon: 'error',
      title: <p>No tienes acceso</p>,
      html: <p>Vuelve a escanear el codigo</p>,
      confirmButtonText: "Cerrar",
    })
    navigate("/");  
  }

 
  
 /*  setuserVar("vengaaaaa"); */

  const [indexStep, setindexStep] = useState(1);
  const [activeStep, setActiveStep] = useState(0);


  const handleNext = () => {
    setindexStep(indexStep + 1);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  };

  const handleBack = () => {
    setindexStep(indexStep - 1);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  };

  const handleReset = () => {
    setActiveStep(0);
  };


  //FORM
  const { register, handleSubmit } = useForm();
  const MySwal = withReactContent(Swal)
  const onSubmit = (formData) => {
/* 
    formData.id_categoria = idRolSelect;

    formData.alergiasDescription = formData.alergiasDescription;
    formData.Login = formData.username;
    formData.Extension = formData.username;
    formData.FullName = formData.nombreMostrar;
    formData.activo = "1"; */

    formData.MenuSelectSegundo = MenuSelectSegundo;
    console.log("el fullname es:", formData);

    
     API.patch("invitados/" + id_inv, formData).then((res) => {
      console.log("Respuesta del registro", res);

      
      MySwal.fire({
        icon: 'success',
        title: <p>Buena eleccion</p>,
        html: <p>Le pasaremos nota al catering</p>,
        confirmButtonText: "🍽",
      }).then(
        navigate("/menuInicial")
      ).then(() => {
        setTimeout(() => window.scrollTo({
          top: 0,
          behavior: 'smooth' // Desplazamiento suave
      }), 1000)


      });
    });

  };

  
  const [alergiasDescription, setalergiasDescription] = useState("");
  const [MenuSelect, setMenuSelect] = useState("");
  const [MenuSelectSegundo, setMenuSelectSegundo] = useState("");


  useEffect(() => {
    const getUsers = async () => {
      const usersIdAPI = await API.get(`/invitados/`+ id_inv);

      console.log("devuelve", usersIdAPI.data);
      setalergiasDescription(usersIdAPI.data.invitados.alergiasDescription);
      setMenuSelect(usersIdAPI.data.invitados.MenuSelect);
      setMenuSelectSegundo(usersIdAPI.data.invitados.MenuSelectSegundo);

   
     /*  localStorage.setItem("alergiasDescription", usersIdAPI.data.Invitados.alergiasDescription);
      setalergiasDescription(usersIdAPI.data.Invitados.alergiasDescription);
      localStorage.setItem("MenuSelect", usersIdAPI.data.Invitados.MenuSelect);
      setMenuSelect(usersIdAPI.data.Invitados.MenuSelect);
      localStorage.setItem("MenuSelectSegundo", usersIdAPI.data.Invitados.MenuSelectSegundo);
      setMenuSelectSegundo(usersIdAPI.data.Invitados.MenuSelectSegundo); */

      
      
     /*  const getCategoriaImg = async () => {
        const categoriaImgAPI = await API.get(`/categorias/id/`+ usersIdAPI.data.Users.id_categoria);
        console.log(categoriaImgAPI); 
        MySwal.fire({
          icon: 'success',
          title: <p>UBICACION</p>,
          html: <div className="ImagenesCategoria">
          <Figure>
            <Figure.Image
              width={500}
              height={250}
              alt={categoriaImgAPI.data.Categoria.name}
              src={categoriaImgAPI.data.Categoria.imagen}
              onClick={() => selectorFigure(categoriaImgAPI.data.Categoria._id)}
            />
            <Figure.Caption>
              {categoriaImgAPI.data.Categoria.description}
            </Figure.Caption>
          </Figure>
        </div>,
          confirmButtonText: "Cerrar",
        })
  
      }; */
      /* getCategoriaImg(); */



      
    };
    getUsers();
  }, []);

  const selectorFigure = (idselect) => {
    const MySwal = withReactContent(Swal)
    MySwal.fire({
      icon: 'success',
      title: <p>Muchas gracias.</p>,
      html: <p>Una maravillosa elección.</p>,
      confirmButtonText: "Cerrar",
    })
    setMenuSelectSegundo(idselect);

  };
  const [IdiomaTextActual, setIdiomaTextActual] = useState({
    "_id": "6599cac40db07abe9ffb9e96",
        "idioma": "espana",
        "cargamusicatext": "¡Sube el volumen!",
        "iconoContinuar": "BTNMUSICESP.png",
        "iconoContinuarsvg": "BTNMUSICESP.svg",
        "imgNoscasamos": "noscasamos.png",
        "titleswalsininv": "No se quién eres\n\n😷\n",
        "textswalsininv": "Debes acceder por el enlace que te hemos enviado. Lo siento.",
        "buttonswalsininv": "¡OK!😒",
        "titleswalfirma": "Necesitamos una firmita por aqui…",
        "buttonswalfirmacancel": "Cerrar",
        "buttonfirmalimpiar": "Limpiar",
        "buttonfirmaguardar": "Guardar",
        "textformlargo": "Todavía estamos ultimando algunos detalles de la boda. Por ello, necesitamos que nos confirmes tus datos de contacto, para poder avisarte de nuevas noticias sobre el evento.",
        "textoinput1": "Móvil:",
        "textoinput2": "Email:",
        "inputtextarea": "¿Se te ocurre algún otro avatar? ¡Dinos cuál! Recuerda que la temática es: flores, plantas…",
        "buttonconfirmacion": "Confirmar asistencia",
        "textosCarta": "Queridos amigos, ¡la hora ha llegado!\nAdrián y Majka, por fin casados.\nSerá en Lupiana, el 20 de septiembre,\nfirmando que su amor dure para siempre.\n\nNo falten a este día tan especial,\ncon baile, comida, ¡será genial!\nOs esperamos con cariño y emoción,\npara ser testigos de esta unión.",
        "textosCheckCarta": "Acepto las condiciones de la invitación",
        "textoCheck1": "Acepto bailar como si nadie estuviera mirando, incluso si todos lo están haciendo, aunque mis movimientos se asemejen más a un pingüino en patinaje sobre hielo",
        "textoCheck2": "Estoy de acuerdo en reírme hasta que me duela la barriga, aunque mi risa suene como una mezcla entre una hiena y un pato",
        "textoCheck3": "Acepto que las fotos embarazosas son una parte obligatoria de la celebración, y me uniré a la diversión sin quejarme, incluso si eso implica usar accesorios ridículos para fotos grupales",
        "textoCheck4": "Prometo no contar chistes malos, a menos que sean tan malos que se vuelvan buenos por accidente",
        "textoCheck5": "Acepto que la pista de baile será mi gimnasio y bailaré hasta quemar más calorías de las consumidas en la cena",
        "textoCheck6": "Acepto tomarme un chupito de Soplica para no hacerle un feo a la novia",
        "textoCheck7": "Estoy dispuesto a aplaudir, gritar \"vivan los novios\" y vitorear exageradamente, animando a los novios y demás invitados como si estuviera en un estadio deportivo",
        "textoCheck8": "Estoy de acuerdo en que los abrazos y besos son obligatorios, y cualquier intento de resistirse será castigado con abrazos y besos aún más fuertes",
        "textoCheck9": "Prometo no rechazar ninguna oferta de comida, postre o delicioso manjar que se cruce en mi camino durante la celebración",
        "textoCheck10": "Acepto que las risas y los recuerdos compartidos superarán cualquier intento de mantener la dignidad intacta",
        "textoCheck11": "Me comprometo a confirmar mi asistencia en fecha. Si no, me llevaré una silla y un sándwich",
        "textoFailCheckTitle": "Acepta, TODAS las condiciones.",
        "textoFailCheck": "Aqui, hemos venido a jugar.",
        "textoSucesCheckTitle": "Gracias por aceptar las condiciones",
        "textoSucesCheck": "Ahora solo queda que elijas tu avatar.",
        "createdAt": "2024-01-06T21:48:52.020Z",
        "updatedAt": "2024-01-21T22:25:53.666Z",
        "__v": 0,
        "cronometroText": "Faltan",
        "imagenLosdosText": "adrianymajkaSUN.png",
        "cronometroTextSegundos": "Seg.",
        "musicaText": "Activa el sonido",
        "textoTyC": "Condiciones de asistencia",
        "textoCarga": "Tenemos algo importante que deciros...\n¡Nos casamos!\n",
        "imagenSello": "sellocartaLogo.svg",
        "imagenSelloM": "sellocartaLogoON.svg"
  });
  useEffect(() => {
    if(localStorage.getItem("invitado")){
      if(localStorage.getItem("textos")){

        const filtrarPorIdioma = (elemento) => elemento.idioma === JSON.parse(localStorage.getItem("invitado")).idioma;
        const arrayTextos = JSON.parse(localStorage.getItem('textos'));
        const resultado = arrayTextos.filter(filtrarPorIdioma);
        console.log("texto de bbdd: ", resultado[0]);
        setIdiomaTextActual(resultado[0]);
      }

    }else{
      Swal.fire({
        icon: 'error',
        title: IdiomaTextActual.titleswalsininv,
        text: IdiomaTextActual.textswalsininv,
        confirmButtonText: IdiomaTextActual.buttonswalsininv
      }).then(result => {
        if (result.isConfirmed) {
          window.location.replace('https://www.google.com/search?q=GNET+-+Grupalia+Internet+S.A.');
        }
     })
      
    }
    
  }, []);    

  const [locpLay, setlocpLay] = useState(audiomp3);
const [imgVolumenShow, setimgVolumenShow] = useState(true);
  return (
    <div className="FondoCarta">
      <audio ref={audioRef} src={locpLay} preload="auto"/>
      <Navbar locpLay={audioRef} imgVolumenShow={imgVolumenShow} musicaText={<a href='/#/MenuInicial'><img src={hamburg}></img></a>}/>
      {/* <div className="divMenu">
        <h1 className="tituloMenu">Opciones del Menu</h1>
      </div> */}
    {true && <form className="registerForm" onSubmit={handleSubmit(onSubmit)}>
      <Box  sx={{ p: 2, 
        /* borderRadius: '2%', 
        border: '2px solid black', */ 
        maxWidth: 700,
        minHeight: 750,
        /* backgroundImage: `url(${fondoCarta})`,
        backgroundSize: '100%', // Ajusta la imagen al tamaño del contenedor
        backgroundRepeat: 'repeat-y', // No repite la imagen */
       }}>
        <Stepper  activeStep={activeStep} orientation="vertical">
          <Step>
            <StepLabel>
              <Typography variant="caption">¿Alguna alergia?</Typography>
            </StepLabel>
            <StepContent>
              <Typography>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Listanos intolerancias o alergias.</Form.Label>
                <Form.Control as="textarea" rows={3} {...register("alergiasDescription", { value: alergiasDescription })} />
                </Form.Group>
              </Typography>
              <Box sx={{ mb: 2 }}>
                <div >
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Continuar
                  </Button>
                  {/* <Button
                     disabled={true}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Volver
                  </Button> */}
                </div>
              </Box>
            </StepContent>
          </Step>
          <Step >
            <StepLabel>
              <Typography variant="caption">¿Que menu vas a querer?</Typography>
            </StepLabel>
            <StepContent>
              <Typography>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Check
                    name="custom-radio"
                    type="radio"
                    id="custom-radio"
                    label="Menu Vegano."
                    value="Vegano"
                    selected="yes"
                    checked={MenuSelect==="Vegano" && true}
                    onClick={() => setMenuSelect("Vegano")}
                    {...register("MenuSelect")}
                  />

                  <Form.Check
                    name="custom-switch"
                    type="radio"
                    id="custom-switch"
                    label="Menu Vegetariano."
                    value="Vegetariano"
                    checked={MenuSelect=="Vegetariano" && true}
                    onClick={() => setMenuSelect("Vegetariano")}
                    {...register("MenuSelect")}
                  />

                  <Form.Check
                    name="custom-switch"
                    type="radio"
                    id="custom-switch"
                    label="Menu Adulto"
                    value="Adulto"
                    checked={MenuSelect=="Adulto" && true}
                    onClick={() => setMenuSelect("Adulto")}
                    {...register("MenuSelect")}
                  />
                  {/*<Form.Check
                    name="custom-switch"
                    type="radio"
                    id="custom-switch"
                    label="Menu para Niñes"
                    value="Niño"
                    checked={MenuSelect=="Niño" && true}
                    onClick={() => setMenuSelect("Niño")}
                    {...register("MenuSelect")}
                  />*/}

                  
                </Form.Group>
              </Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Continuar
                  </Button>
                  <Button
                    /*  disabled={true} */
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Volver
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          <Step>
          <StepLabel>
            <Typography variant="caption">¿Que Segundo vas a Querer?</Typography>
          </StepLabel>
          <StepContent>
            <Typography>

            <Form.Group className="mb-3" controlId="formBasicEmail">
            <Figure className={MenuSelectSegundo=="carne" && "golden-border"}>
                            <Figure.Image
                              width={250}
                              height={250}
                              alt="Carne"
                              src={SOLOMILLOPNG}
                              onClick={() => selectorFigure("carne")}
                            />
                            <Figure.Caption>
                              Solomillo de res con patata asada.
                            </Figure.Caption>
                          </Figure>

                
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
            <Figure className={MenuSelectSegundo=="pescado" && "golden-border"}>
                            <Figure.Image
                              width={250}
                              height={250}
                              alt="Pescado"
                              src={LUBINAPNG}
                              onClick={() => selectorFigure("pescado")}
                            />
                            <Figure.Caption>
                              Taco de lubina salvaje con velouté <br></br>de ajetes tiernos.
                            </Figure.Caption>
                          </Figure>

                
              </Form.Group>
            </Typography>
            <Box sx={{ mb: 2 }}>
              <div>
                {/* <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Continue
                </Button> */}
                <Button
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Volver
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
        </Stepper>
        <Paper square elevation={0} sx={{ p: 2 }}>
          {indexStep === 3 ? (<Button type="submit" variant="contained" size="large">Enviar Menu</Button>) : (<></>)}


        </Paper>
      </Box>
    </form>}
    </div>              


  );
};

export default Menu;
