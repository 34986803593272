import React, {useState, useEffect, useRef} from 'react'
import { useParams, useNavigate } from "react-router-dom";
import Navbar from '../Navbar/Navbar';

import GIRASOLES1 from './../../imagenes/svg/GIRASOLES1V.svg';
import GIRASOLES2 from './../../imagenes/svg/GIRASOLES2V.svg';
import GIRASOLES3 from './../../imagenes/svg/GIRASOLES3V.svg';
import GIRASOLES25 from './../../imagenes/svg/GIRASOLES25.svg';
import GIRASOLESABEJA from './../../imagenes/GIRASOLESABEJAV.png';
import SEPARADORFLORAL from './../../imagenes/svg/SEPARADORFLORAL.svg';

import GIRASOLSELECCION from './../../imagenes/GIRASOLSELECCIONV.png';
import GIRASOLSELECCIONon from './../../imagenes/GIRASOLSELECCIONVon.png';

import FONDOCIELO from './../../imagenes/FONDOCIELO.png';

import MONASTERIO  from './../../imagenes/MONASTERIO.png';

import monasterioGirasoles from './../../imagenes/svg/monasterioGirasoles.svg';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

import audiomp3 from './../../audio/sonidonaturaleza.mp3';


import Carrusel from './Carrusel';

import Firma from './Firma';

import { API } from "../../services/api";


import CargaVideo from "../../carga/cargaVideo"

import hamburg from './../../imagenes/bar-de-hamburguesas.png';

import './Registro.css';

const Registro = () => {

  const inputRef = useRef(null);
  const navigate = useNavigate();
  const audioRef = useRef(null);


  const [cargaVideoShow, setcargaVideoShow] = useState(false);




  const cargaVideoON = (valor) =>{
      setcargaVideoShow(valor);
  } 




  const [locpLay, setlocpLay] = useState(audiomp3);
  const [imgVolumenShow, setimgVolumenShow] = useState(false);
    
    /* const navigate = useNavigate(); */

    //TEXTOS INCIIALES
    const [IdiomaTextActual, setIdiomaTextActual] = useState({
      "_id": "6599cac40db07abe9ffb9e96",
			"idioma": "espana",
			"imgNoscasamos": "noscasamos.png",
			"textosCarta": "Queridos amigos, ¡la hora ha llegado!\nAdrián y Majka, por fin casados.\nSerá en Lupiana, el 20 de septiembre,\nfirmando que su amor dure para siempre.\n\nNo falten a este día tan especial,\ncon baile, comida, ¡será genial!\nOs esperamos con cariño y emoción,\npara ser testigos de esta unión.",
			"textosCheckCarta": "Pasar a aceptar las condiciones de asistencia",
			"textoCheck1": "Acepto bailar como si nadie estuviera mirando, incluso si todos lo están haciendo, aunque mis movimientos se asemejen más a un pingüino en patinaje sobre hielo",
			"textoCheck2": "Estoy de acuerdo en reírme hasta que me duela la barriga, aunque mi risa suene como una mezcla entre una hiena y un pato",
			"textoCheck3": "Acepto que las fotos embarazosas son una parte obligatoria de la celebración, y me uniré a la diversión sin quejarme, incluso si eso implica usar accesorios ridículos para fotos grupales",
			"textoCheck4": "Prometo no contar chistes malos, a menos que sean tan malos que se vuelvan buenos por accidente",
			"textoCheck5": "Acepto que la pista de baile será mi gimnasio y bailaré hasta quemar más calorías de las consumidas en la cena",
			"textoCheck6": "Acepto tomarme un chupito de Soplica para no hacerle un feo a la novia",
			"textoCheck7": "Estoy dispuesto a aplaudir, gritar \"vivan los novios\" y vitorear exageradamente, animando a los novios y demás invitados como si estuviera en un estadio deportivo",
			"textoCheck8": "Estoy de acuerdo en que los abrazos y besos son obligatorios, y cualquier intento de resistirse será castigado con abrazos y besos aún más fuertes",
			"textoCheck9": "Prometo no rechazar ninguna oferta de comida, postre o delicioso manjar que se cruce en mi camino durante la celebración",
			"textoCheck10": "Acepto que las risas y los recuerdos compartidos superarán cualquier intento de mantener la dignidad intacta",
			"textoCheck11": "Me comprometo a confirmar mi asistencia en fecha. Si no, me llevaré una silla y un sándwich",
			"textoFailCheckTitle": "Acepta, TODAS las condiciones.",
			"textoFailCheck": "Aqui, hemos venido a jugar.",
			"textoSucesCheckTitle": "Gracias por aceptar las condiciones",
			"textoSucesCheck": "Ahora solo queda que elijas tu avatar.",
			"createdAt": "2024-01-06T21:48:52.020Z",
			"updatedAt": "2024-03-31T14:31:02.328Z",
			"__v": 0,
			"cronometroText": "Faltan",
			"imagenLosdosText": "adrianymajkaSUN.png",
			"cronometroTextSegundos": "Segundos",
			"musicaText": "Activa el sonido",
			"textoTyC": "Condiciones de asistencia",
			"textoCarga": "Tenemos algo importante que deciros...\n¡Nos casamos!\n",
			"imagenSello": "sellocartaLogo.svg",
			"imagenSelloM": "sellocartaLogoON.svg",
			"buttonfirmaguardar": "Guardar",
			"buttonfirmalimpiar": "Limpiar",
			"buttonswalfirmacancel": "Cerrar",
			"cargamusicatext": "¡Sube el volumen!",
			"iconoContinuar": "BTNMUSICESP.png",
			"iconoContinuarsvg": "BTNMUSICESP.svg",
			"inputtextarea": "¿Se te ocurre algún otro avatar? ¡Dinos cuál! Recuerda que la temática es: flores, plantas…",
			"textformlargo": "Todavía estamos ultimando algunos detalles de la boda. Por ello, necesitamos que nos confirmes tus datos de contacto, para poder avisarte de nuevas noticias sobre el evento.",
			"textoinput1": "Móvil:",
			"textoinput2": "Email:",
			"textswalsininv": "Debes acceder por el enlace que te hemos enviado. Lo siento.",
			"titleswalfirma": "Necesitamos una firmita por aqui…",
			"titleswalsininv": "No se quién eres\n\n😷\n",
			"buttonconfirmacion": "Confirmar asistencia",
			"texttemporizador": "¿Cuánto falta para el gran día?",
			"texttemporizadorsegundos": "Tan solo faltan",
			"textlobyfinal": "Os iremos avisando tan pronto tengamos todos los detalles del evento. Mientras tanto, por favor, esperad aquí junto al resto de invitados.",
			"swalnoregistro": "Esperamos que puedas venir",
			"textoMorfeo": "Tomas la píldora azul... la historia termina, te despiertas en tu cama y crees lo que quieras creer. Tomas la píldora roja... te quedas en el País de las Maravillas, y te enseño lo profunda que es la madriguera del conejo",
			/* "videoRick": "rickadmortyvideo.mp4", */
      "titlealertseleccion": "Toca elegir el avatar.",
	"mensajealertseleccion": "Recuerda que tienes varios para elegir, tomate tu tiempo.",
	"botonalertseleccion": "Aceptar",
  "botonalertavatar": "Lo quiero!",
  "titlealertavatar": "¿Quieres este avatar?"
    });
//SACO AVATARES
      const [Avatares, setAvatares] = useState([
        {
          "_id": "65786b219653cdc512ccdb10",
          "name": "dwight",
          "imagen1": "dwight.svg",
          "imagen2": "dwight.png",
          "createdAt": "2023-12-12T14:16:01.327Z",
          "updatedAt": "2023-12-12T14:16:01.327Z",
          "__v": 0
        },
        {
          "_id": "65786b259653cdc512ccdb12",
          "name": "firasolZombie",
          "imagen1": "firasolZombie.svg",
          "imagen2": "firasolZombie.png",
          "createdAt": "2023-12-12T14:16:05.114Z",
          "updatedAt": "2023-12-12T14:16:05.114Z",
          "__v": 0
        }]);

  useEffect(() => {
    if(localStorage.getItem("invitado")){
      if(JSON.parse(localStorage.getItem("invitado"))._id == "664f43c02271de7b324ad38c" || JSON.parse(localStorage.getItem("invitado"))._id == "664eeb142271de7b324ad308"){
        setcargaVideoShow(false);
      }

      const getAvataresId = async () => {
        const userInv =  await API.get("/avatares").then((res) => {
          console.log("respuesta",res);
          setAvatares(res.data.Avatares);
        })
      };
      if(localStorage.getItem("textos")){

        const filtrarPorIdioma = (elemento) => elemento.idioma === JSON.parse(localStorage.getItem("invitado")).idioma;
        const arrayTextos = JSON.parse(localStorage.getItem('textos'));
        const resultado = arrayTextos.filter(filtrarPorIdioma);
        console.log("texto de bbdd: ", resultado[0]);
        setIdiomaTextActual(resultado[0]);
      }

      getAvataresId();
    }else{
      Swal.fire({
        icon: 'error',
        title: IdiomaTextActual.titleswalsininv,
        text: IdiomaTextActual.textswalsininv,
        confirmButtonText: IdiomaTextActual.buttonswalsininv
      }).then(result => {
        if (result.isConfirmed) {
          window.location.replace('https://www.google.com/search?q=GNET+-+Grupalia+Internet+S.A.');
        }
     })
      
    }
    
  }, []);
  
  //FORMULARIO INVITACION

  const [mobileNumber, setMobileNumber] = useState(JSON.parse(localStorage.getItem("invitado")).nmovil);
  const [email, setEmail] = useState(JSON.parse(localStorage.getItem("invitado")).email);
  const [message, setMessage] = useState(JSON.parse(localStorage.getItem("invitado")).mensaje);
  const [idavatar, setidavatar] = useState('');
  console.log( "datos guardados del inv:", JSON.parse(localStorage.getItem("invitado")));
  const handleSubmit = (event) => {
    event.preventDefault();

      API.patch('/invitados/'+JSON.parse(localStorage.getItem("invitado"))._id, {
        "nmovil": mobileNumber,
        "email": email,
      "mensaje": message,
      "id_avatares": idavatar,
    })
    .then(response => {
      console.log("RESPON POST INV", response);
      localStorage.setItem("invitado", JSON.stringify(response.data.actual));
      /* Swal.fire({
        icon: 'success',
        title: 'Gracias por asistir a nuestra boda.',
        text: 'Proximamente tendremos mas novedades. Cuando hagamos alguna actualización con información de la boda te lo notificaremos en tu correo y a tu movil. Nos vemos pronto!!',
        
        confirmButtonText: '<i class="fas fa-check"></i> Aceptar',
              confirmButtonColor: '#2ecc71',
              confirmButtonAriaLabel: 'Aceptar'
      }).then(result => {
        if (result.isConfirmed) {
            navigate("/loby");
        }
     }); */
     const MySwal = withReactContent(Swal);
     MySwal.fire({
      title: IdiomaTextActual.titleswalfirma,
      html: <Firma navigate={navigate("/loby")} IdiomaTextActual={IdiomaTextActual}/>,
      showCancelButton: true,
      cancelButtonText: IdiomaTextActual.buttonswalfirmacancel,
      showConfirmButton: false,
    }).then(result => {
      if (result.isConfirmed) {
          navigate("/loby");
      }
   });
      
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Hubo un error de conexión',
        text: 'Revisa si tienes conexión a internet y vuelve a enviarme la información.'
      })
      console.error(error);
    });
  };
  

  const [SelectAvatar, setSelectAvatar] = useState(true);

  
      return (
      <>
      <audio ref={audioRef} src={locpLay} preload="auto"/>
      <Navbar locpLay={audioRef} imgVolumenShow={imgVolumenShow} musicaText={<a href='/#/MenuInicial'><img src={hamburg}></img></a>}/>
      <div className='fondo1' >
        <img className='IMGFONDO' src= {GIRASOLES1} alt='Logo1'/>
        <img className='IMGFONDO' src= {GIRASOLES2} alt='Logo1'/>
        <img className='IMGFONDO' src= {GIRASOLES3} alt='Logo1'/>
        <img className='IMGFONDO' src= {GIRASOLESABEJA} alt='Logo1'/>
        
      </div>
      <div className='fondo2' >
      {/* <Firma></Firma> */}
      <img className='IMGFONDO' src= {GIRASOLES3} alt='Logo1'/>
      {cargaVideoShow ? <></> : <Carrusel imagenes={Avatares} cargaVideoShow={cargaVideoShow} setSelectAvatar={setSelectAvatar} setidavatar={setidavatar} IdiomaTextActual={IdiomaTextActual} focusForm={inputRef.current}></Carrusel>}
        {/* <img className='IMGFONDO' src= {GIRASOLSELECCIONon} alt='Logo1'/> */}
        {/* <img className='IMGFONDO' src= {MONASTERIO} alt='Logo1'/> */}
        {SelectAvatar ? <img className='IMGFONDO' src= {GIRASOLES25} /> : <>
        
        <form onSubmit={handleSubmit} className="form">
              <div className="form_div1">
              <img className='IMGFONDO SEPARADOR' src= {SEPARADORFLORAL} alt='Logo1'/>
              <div className="textoPreForm">
                <p className="intro">{IdiomaTextActual.textformlargo}</p>
              </div>
              </div>
              <div className="form_div2">
             
                <label htmlFor="mobileNumber" className="form__label">
                  <b>*{IdiomaTextActual.textoinput1}</b>
                  <input
                    type="tel"
                    id="mobileNumber"
                    value={mobileNumber}
                    onChange={(event) => setMobileNumber(event.target.value)}
                    pattern="^\+34[6789]\d{8}$|^[6789]\d{8}$|^\+393\d{9}$|^3\d{9}$|^\+390\d{6,9}$|^0\d{6,9}$"
                    required
                    className="form__input"
                  />
                </label>
                <label htmlFor="email" className="form__label">
                  <b>*{IdiomaTextActual.textoinput2}</b>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    required
                    className="form__input"
                  />
                </label>
                <label htmlFor="message" className="form__label textarea">{IdiomaTextActual.inputtextarea}</label>
              <textarea id="message" value={message} className="form__input textarea" onChange={(e) => setMessage(e.target.value)} />

                <button type="submit" className="form__button">
                  {IdiomaTextActual.buttonconfirmacion}
                </button>
              </div>

            </form>
      <img className='IMGFONDO' src= {monasterioGirasoles} alt='Logo1'/>
      </>}

            
      
      <span ref={inputRef} id="myInput" />
      </div>
      {cargaVideoShow && <CargaVideo IdiomaTextActual={IdiomaTextActual} quitarCarga={(cargaVideoON)} locpLay={audioRef}></CargaVideo>}
      </>
      );
    }
  
  export default Registro