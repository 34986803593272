import React, { useEffect, useState } from 'react';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import './Galeria.css'; // Import the new CSS file

const Galeria = () => {
  const [photos, setPhotos] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(1);
  const [selectedPhotos, setSelectedPhotos] = useState([]);

  useEffect(() => {
    fetch('https://bbdd.adrianymarianna.com/fotos/BodaInicial')
      .then((response) => response.json())
      .then(data => {
        const GaleriaFetch = data.Fotos;
        const formattedPhotos = GaleriaFetch.map((item, index) => {
          try {
            return {
              src: require(`./../../fotos/${item.tipo}/${item.nombreFoto}`),
              width: item.width,
              height: item.height,
              key: `${item.nombreFoto}`, // Use a unique key
              id: index // Unique identifier for selection
            };
          } catch (error) {
            console.error(`Error cargando la imagen ${item.nombreFoto}:`, error);
            return null;
          }
        }).filter(photo => photo !== null);

        setPhotos(formattedPhotos);
        console.log(formattedPhotos);
      })
      .catch((error) => console.error('Error al cargar las fotos:', error));
  }, []);

  const handlePhotoClick = (event, { index, photo }) => {
    if (!photo || (!photo.id && photo.id !== 0)) {
      console.warn('Photo object is invalid or undefined.');
      return;
    }

    const isSelected = selectedPhotos.some((selected) => selected.id === photo.id);
    if (isSelected) {
      setSelectedPhotos(selectedPhotos.filter((selected) => selected.id !== photo.id));
    } else {
      setSelectedPhotos([...selectedPhotos, photo]);
    }
  };

  const handleDownload = async () => {
    if (selectedPhotos.length === 0) {
      alert('No hay fotos seleccionadas para descargar.');
      return;
    }

    const zip = new JSZip();
    const folder = zip.folder('GaleriaDescarga');

    for (const photo of selectedPhotos) {
      try {
        const response = await fetch(photo.src);
        const blob = await response.blob();
        const fileName = photo.src.split('/').pop();
        folder.file(fileName, blob);
      } catch (error) {
        console.error(`Error al descargar la imagen ${photo.src}:`, error);
      }
    }

    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, 'galeria.zip');
    });
  };

  const handleSinglePhotoDownload = (src) => {
    fetch(src)
      .then(response => response.blob())
      .then(blob => {
        const fileName = src.split('/').pop();
        saveAs(blob, fileName);
      })
      .catch(error => console.error('Error al descargar la imagen:', error));
  };

  const renderImage = ({ photo, index }) => {
    const isSelected = selectedPhotos.some((selected) => selected.id === photo.id);
    return (
      <div
        onClick={(e) => handlePhotoClick(e, { index, photo })}
        className={`photo-container ${isSelected ? 'selected' : ''}`}
      >
        <img
          src={photo.src}
          alt="Galería"
          width={photo.width}
          height={photo.height}
        />
      </div>
    );
  };

  const openLightbox = () => {
    if (!lightboxOpen && photos.length > 0) {
      if(photoIndex == 0){
        setTimeout(() => setPhotoIndex((1 - 1) % photos.length), 100);
      }
      else{
        setTimeout(() => setPhotoIndex((photoIndex - 1) % photos.length), 100);
      }
      
      setLightboxOpen(true);
    }
  };

  return (
    <div className="galeria-container">
      <div className="buttons-container">
        <button className="icon-button" onClick={openLightbox}>
          <span role="img" aria-label="Galería">🖼️</span>
        </button>
        <button className="icon-button" onClick={handleDownload}>
          <span role="img" aria-label="Descargar">⬇️</span>
        </button>
      </div>
      <h2>Galería de Fotos</h2>
      {photos.length > 0 ? (
        <div className="gallery-wrapper">
          <Gallery
            photos={photos}
            renderImage={renderImage}
          />
        </div>
      ) : (
        <p>Cargando fotos...</p>
      )}
      {lightboxOpen && photos && (
        <Lightbox
          mainSrc={lightboxOpen && photos[photoIndex].src}
          nextSrc={photos[(photoIndex + 1) % photos.length].src}
          prevSrc={photos[(photoIndex + photos.length - 1) % photos.length].src}
          onCloseRequest={() => setLightboxOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % photos.length)
          }
          toolbarButtons={[<button onClick={() => handleSinglePhotoDownload(photos[photoIndex].src)} className="download-button">⬇️</button>]}
        />
      )}
    </div>
  );
};

export default Galeria;
