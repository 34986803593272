// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.fotolosdosfase3{
    width: 95%;

}

.fondoLoginInicial3{
	background-color: black;
    height: 1000px;
    color: white;
    text-align: center;
}

`, "",{"version":3,"sources":["webpack://./src/pages/MenuInicial/MenuInicialLoginfase3.css"],"names":[],"mappings":";;AAEA;IACI,UAAU;;AAEd;;AAEA;CACC,uBAAuB;IACpB,cAAc;IACd,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":["\n\n.fotolosdosfase3{\n    width: 95%;\n\n}\n\n.fondoLoginInicial3{\n\tbackground-color: black;\n    height: 1000px;\n    color: white;\n    text-align: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
