// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../../imagenes/fondoMenuComidaMonasterio.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.golden-border {
    border: 5px solid gold; /* Borde dorado */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Sombra */
    border-radius: 10px; /* Opcional: Para bordes redondeados */
    overflow: hidden; /* Asegura que el contenido no se desborde */
    margin: 0 auto;
    padding: 1em;
  }
.divMenu{
  text-align: center;
}
.FondoCarta{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: 100% auto; /* Ajustar el ancho al 100% y la altura automáticamente */
    background-position: bottom center; /* Fijar la imagen al fondo y centrarla horizontalmente */
    background-repeat: no-repeat; /* Evitar que la imagen se repita */
    height: 100%;
}

.tituloMenu{
  color: blue;
  
}

.registerForm{

  padding-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Menu/Menu.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB,EAAE,iBAAiB;IACzC,0CAA0C,EAAE,WAAW;IACvD,mBAAmB,EAAE,sCAAsC;IAC3D,gBAAgB,EAAE,4CAA4C;IAC9D,cAAc;IACd,YAAY;EACd;AACF;EACE,kBAAkB;AACpB;AACA;IACI,yDAAuE;IACvE,0BAA0B,EAAE,yDAAyD;IACrF,kCAAkC,EAAE,yDAAyD;IAC7F,4BAA4B,EAAE,mCAAmC;IACjE,YAAY;AAChB;;AAEA;EACE,WAAW;;AAEb;;AAEA;;EAEE,oBAAoB;AACtB","sourcesContent":[".golden-border {\n    border: 5px solid gold; /* Borde dorado */\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Sombra */\n    border-radius: 10px; /* Opcional: Para bordes redondeados */\n    overflow: hidden; /* Asegura que el contenido no se desborde */\n    margin: 0 auto;\n    padding: 1em;\n  }\n.divMenu{\n  text-align: center;\n}\n.FondoCarta{\n    background-image: url(\"./../../imagenes/fondoMenuComidaMonasterio.jpg\");\n    background-size: 100% auto; /* Ajustar el ancho al 100% y la altura automáticamente */\n    background-position: bottom center; /* Fijar la imagen al fondo y centrarla horizontalmente */\n    background-repeat: no-repeat; /* Evitar que la imagen se repita */\n    height: 100%;\n}\n\n.tituloMenu{\n  color: blue;\n  \n}\n\n.registerForm{\n\n  padding-bottom: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
