const apiKey = 'AIzaSyC7BsOub2_mI_hwPxCkpHMgZkB1O9Xit1E';  // Reemplaza con tu clave de API
const placeName = 'Hotel Restaurante la Cañada';  // Reemplaza con el nombre del negocio

fetch(`https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=${encodeURIComponent(placeName)}&inputtype=textquery&fields=place_id&key=${apiKey}`)
  .then(response => response.json())
  .then(data => {
    if (data.candidates && data.candidates.length > 0) {
      const placeId = data.candidates[0].place_id;
      console.log(`Place ID: ${placeId}`);
    } else {
      console.log('No se encontró ningún lugar con ese nombre.');
    }
  })
  .catch(error => {
    console.error('Error al buscar el Place ID:', error);
  });