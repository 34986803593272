import axios from "axios";



export const APIHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Authorization': {
      toString () {
         /*  return `Bearer ${localStorage.getItem('token')}` */
          return `Bearer TuputamadreLuis`
      }
  }
};

export const APIHeadersItunes = {
  'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept',
  'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
  'Access-Control-Allow-Origin': '*'
};

export const API = axios.create({
    /* baseURL: "http://212.230.190.232:8800/", */
    /* baseURL: "http://192.168.1.222:8800/", */
    baseURL: "https://bbdd.adrianymarianna.com/",
    headers: APIHeadersItunes,
  });

  export const APIitunes = axios.create({
    baseURL: "https://itunes.apple.com/search",
    headers: APIHeaders,
  });