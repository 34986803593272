import {React, useState, useEffect, useRef} from 'react'
import { LoadScript, GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import Swal from 'sweetalert2';
import Navbar from './../../pages/Navbar/Navbar';
import audiomp3 from './../../audio/sonidonaturaleza.mp3';
import { API } from "../../services/api";
import ButtonHOTEL from './BOTONHOTEL';

import hamburg from './../../imagenes/bar-de-hamburguesas.png';
import './Hotel2.css';

import BOTONHOTEL from "./../../imagenes/svg/BOTONHOTEL.svg";
import BOTONHOTELON from "./../../imagenes/svg/BOTONHOTELON.svg";

const GOOGLE_MAPS_API_KEY = 'AIzaSyDXFaX4QNVJYEHgWveushFcdrj1KHhmI4Y'; // Reemplaza con tu API Key de Google Maps

const libraries = ['places'];

/* const placeId = 'ChIJwZjrOqirQw0Rgx52Cu1P1q0'; // Reemplaza con el Place ID del hotel */
const placeId = 'ChIJYziO4wmrQw0RPkfOvKt8dbc'; // Reemplaza con el Place ID del hotel


const mapContainerStyle = {
    width: '100%',
    height: '500px'
  };
  
  const GooglePlaceDetails = () => {
    const [placeDetails, setPlaceDetails] = useState(null);
    const [map, setMap] = useState(null);
    const [infoWindowOpen, setInfoWindowOpen] = useState(false);
    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const [invitadosReservaHotel, setinvitadosReservaHotel] = useState("sinReserva");

    const audioRef = useRef(null);


    useEffect(() => {
      if (map) {
        const service = new window.google.maps.places.PlacesService(map);
        service.getDetails(
          { placeId },
          (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              setPlaceDetails(place);
              setCenter({
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
              });
            }
          }
        );
      }
    }, [map]);

    const handleCopy = (text) => {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(text).then(() => {
          Swal.fire({
            icon: 'success',
            title: '¡Copiado!',
            text: 'La dirección ha sido copiada al portapapeles.',
            timer: 1500,
            showConfirmButton: false
          });
        }).catch(err => {
          console.error('Error al copiar: ', err);
        });
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand('copy');
          Swal.fire({
            icon: 'success',
            title: '¡Copiado!',
            text: 'La dirección ha sido copiada al portapapeles.',
            timer: 1500,
            showConfirmButton: false
          });
        } catch (err) {
          console.error('Error al copiar: ', err);
        }
        document.body.removeChild(textArea);
      }
    };

    const [IdiomaTextActual, setIdiomaTextActual] = useState({
      "_id": "6599cac40db07abe9ffb9e96",
          "idioma": "espana",
          "cargamusicatext": "¡Sube el volumen!",
          "iconoContinuar": "BTNMUSICESP.png",
          "iconoContinuarsvg": "BTNMUSICESP.svg",
          "imgNoscasamos": "noscasamos.png",
          "titleswalsininv": "No se quién eres\n\n😷\n",
          "textswalsininv": "Debes acceder por el enlace que te hemos enviado. Lo siento.",
          "buttonswalsininv": "¡OK!😒",
          "titleswalfirma": "Necesitamos una firmita por aqui…",
          "buttonswalfirmacancel": "Cerrar",
          "buttonfirmalimpiar": "Limpiar",
          "buttonfirmaguardar": "Guardar",
          "textformlargo": "Todavía estamos ultimando algunos detalles de la boda. Por ello, necesitamos que nos confirmes tus datos de contacto, para poder avisarte de nuevas noticias sobre el evento.",
          "textoinput1": "Móvil:",
          "textoinput2": "Email:",
          "inputtextarea": "¿Se te ocurre algún otro avatar? ¡Dinos cuál! Recuerda que la temática es: flores, plantas…",
          "buttonconfirmacion": "Confirmar asistencia",
          "textosCarta": "Queridos amigos, ¡la hora ha llegado!\nAdrián y Majka, por fin casados.\nSerá en Lupiana, el 20 de septiembre,\nfirmando que su amor dure para siempre.\n\nNo falten a este día tan especial,\ncon baile, comida, ¡será genial!\nOs esperamos con cariño y emoción,\npara ser testigos de esta unión.",
          "textosCheckCarta": "Acepto las condiciones de la invitación",
          "textoCheck1": "Acepto bailar como si nadie estuviera mirando, incluso si todos lo están haciendo, aunque mis movimientos se asemejen más a un pingüino en patinaje sobre hielo",
          "textoCheck2": "Estoy de acuerdo en reírme hasta que me duela la barriga, aunque mi risa suene como una mezcla entre una hiena y un pato",
          "textoCheck3": "Acepto que las fotos embarazosas son una parte obligatoria de la celebración, y me uniré a la diversión sin quejarme, incluso si eso implica usar accesorios ridículos para fotos grupales",
          "textoCheck4": "Prometo no contar chistes malos, a menos que sean tan malos que se vuelvan buenos por accidente",
          "textoCheck5": "Acepto que la pista de baile será mi gimnasio y bailaré hasta quemar más calorías de las consumidas en la cena",
          "textoCheck6": "Acepto tomarme un chupito de Soplica para no hacerle un feo a la novia",
          "textoCheck7": "Estoy dispuesto a aplaudir, gritar \"vivan los novios\" y vitorear exageradamente, animando a los novios y demás invitados como si estuviera en un estadio deportivo",
          "textoCheck8": "Estoy de acuerdo en que los abrazos y besos son obligatorios, y cualquier intento de resistirse será castigado con abrazos y besos aún más fuertes",
          "textoCheck9": "Prometo no rechazar ninguna oferta de comida, postre o delicioso manjar que se cruce en mi camino durante la celebración",
          "textoCheck10": "Acepto que las risas y los recuerdos compartidos superarán cualquier intento de mantener la dignidad intacta",
          "textoCheck11": "Me comprometo a confirmar mi asistencia en fecha. Si no, me llevaré una silla y un sándwich",
          "textoFailCheckTitle": "Acepta, TODAS las condiciones.",
          "textoFailCheck": "Aqui, hemos venido a jugar.",
          "textoSucesCheckTitle": "Gracias por aceptar las condiciones",
          "textoSucesCheck": "Ahora solo queda que elijas tu avatar.",
          "createdAt": "2024-01-06T21:48:52.020Z",
          "updatedAt": "2024-01-21T22:25:53.666Z",
          "__v": 0,
          "cronometroText": "Faltan",
          "imagenLosdosText": "adrianymajkaSUN.png",
          "cronometroTextSegundos": "Seg.",
          "musicaText": "Activa el sonido",
          "textoTyC": "Condiciones de asistencia",
          "textoCarga": "Tenemos algo importante que deciros...\n¡Nos casamos!\n",
          "imagenSello": "sellocartaLogo.svg",
          "imagenSelloM": "sellocartaLogoON.svg"
    });
    useEffect(() => {
      if(localStorage.getItem("invitado")){

        const getCancionesId = async () => {
          const userInv =  await API.get('/invitados/'+JSON.parse(localStorage.getItem("invitado"))._id).then((res) => {
            console.log("respuesta",res);
            setinvitadosReservaHotel(res.data.invitados.reservaHotel);
          })
        };
        getCancionesId();



        if(localStorage.getItem("textos")){
  
          const filtrarPorIdioma = (elemento) => elemento.idioma === JSON.parse(localStorage.getItem("invitado")).idioma;
          const arrayTextos = JSON.parse(localStorage.getItem('textos'));
          const resultado = arrayTextos.filter(filtrarPorIdioma);
          console.log("texto de bbdd: ", resultado[0]);
          setIdiomaTextActual(resultado[0]);
        }
  
      }else{
        Swal.fire({
          icon: 'error',
          title: IdiomaTextActual.titleswalsininv,
          text: IdiomaTextActual.textswalsininv,
          confirmButtonText: IdiomaTextActual.buttonswalsininv
        }).then(result => {
          if (result.isConfirmed) {
            window.location.replace('https://www.google.com/search?q=GNET+-+Grupalia+Internet+S.A.');
          }
       })
        
      }
      
    }, []);    

    const [locpLay, setlocpLay] = useState(audiomp3);
  const [imgVolumenShow, setimgVolumenShow] = useState(true);
  
    return (
      <div className="hoteldiv">
        <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap" rel="stylesheet"></link>
      <audio ref={audioRef} src={locpLay} preload="auto"/>
      <Navbar locpLay={audioRef} imgVolumenShow={imgVolumenShow} musicaText={<a href='/#/MenuInicial'><img src={hamburg}></img></a>}/>
      <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={libraries}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={15}
          onLoad={(mapInstance) => setMap(mapInstance)}
        >
          {placeDetails && (
            <Marker
              position={placeDetails.geometry.location}
              onClick={() => setInfoWindowOpen(true)}
            />
          )}

          {infoWindowOpen && placeDetails && (
            <InfoWindow
              position={placeDetails.geometry.location}
              onCloseClick={() => setInfoWindowOpen(false)}
            >
              <div>
                <h2>{placeDetails.name}</h2>
                <p>{placeDetails.formatted_address}</p>
                <p>{placeDetails.formatted_phone_number}</p>
                {placeDetails.website && (
                  <p>
                    <a href={placeDetails.website} target="_blank" rel="noopener noreferrer">
                      Sitio Web
                    </a>
                  </p>
                )}
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
        {placeDetails && (
          <div>
            <h2>{placeDetails.name}</h2>
            <div>
              <h3>Precios</h3>
              <p className="custom-list"><s > Habitación doble o doble de uso individual:<br></br> <b>68€/noche</b></s></p>
              <p>Habitación doble o doble de uso individual:<br></br> <b>Segun disponibilidad</b></p>
            </div>
           {/*  <div>
              <h3>Información importante</h3>
              <ul className="custom-list">
  <li>
    Al realizar la reserva recuerda decir que es una reserva para el enlace de <b>Adrián y Majka</b>
  </li>
  <li>
    Para poder disfrutar de este precio tenéis que reservar la habitación <b>antes del 19 de agosto</b>. Después de esta fecha podréis reservar habitaciones, pero serán más caras, <br></br>¡así que daros prisa!
 </li>
  <li>
    Os pedirán vuestros datos y una tarjeta, ya que <b>el pago se realiza por adelantado el 19 de agosto</b>. Una vez hecho el pago, no se podrá anular sin coste.
  </li>
</ul>

            </div> */}
            <div>
              <h3>Información importante</h3>
              <ul className="custom-list">
 
  <li>
  <s>Para poder disfrutar de este precio tenéis que reservar la habitación <b>antes del 19 de agosto</b>. Después de esta fecha podréis reservar habitaciones, pero serán más caras, <br></br>¡así que daros prisa!</s>
 </li>
 <li>
  <s>Os pedirán vuestros datos y una tarjeta, ya que <b>el pago se realiza por adelantado el 19 de agosto</b>. Una vez hecho el pago, no se podrá anular sin coste.</s>
  </li>
  <li>
    Al realizar la reserva, recuerda decir que es una reserva para el enlace de <b>Adrián y Majka</b>.
</li>
<li>
    El hotel os pedirá que rellenéis un PDF para el pago. <b>Permiten tarjeta o transferencia</b> para abonar la habitación.
</li>
<li>
    Para reservar en Booking u otros hoteles con <b>cancelación gratuita hasta el día del enlace,</b> <br/>Puedes pulsar aquí: <a href="https://www.booking.com/hotel/es/pax-chi.es.html?aid=356935&label=metagha-link-LUES-hotel-93688_dev-desktop_los-1_bw-90_dow-Sunday_defdate-1_room-0_gstadt-2_rateid-ein1_aud-7380914326_gacid-21404702604_mcid-10_ppa-0_clrid-0_ad-1_gstkid-0_checkin-20241117_ppt-G1&sid=aeb1a39b673afb6d63bd5eaf6ff064ea&all_sr_blocks=9368832_367840455_2_42_0;checkin=2024-09-20;checkout=2024-09-21;dest_id=-384580;dest_type=city;dist=0;group_adults=2;group_children=0;hapos=1;highlighted_blocks=9368832_367840455_2_42_0;hpos=1;matching_block_id=9368832_367840455_2_42_0;no_rooms=1;req_adults=2;req_children=0;room1=A%2CA;sb_price_type=total;sr_order=popularity;sr_pri_blocks=9368832_367840455_2_42_0__12000;srepoch=1724092628;srpvid=118682dbe9ce0c9d;type=total;ucfs=1&">
        🏨🛌🏩
    </a>
</li>

</ul>

            </div>
            <div>
              <h3>Datos del Hotel</h3>
              <b>Dirección:</b><p className='enlacecopi' onClick={() => handleCopy(placeDetails.formatted_address)}>{placeDetails.formatted_address}</p>
              <b>Teléfono:</b><p><a href={"tel:"+placeDetails.formatted_phone_number}>{placeDetails.formatted_phone_number}</a></p>
              {placeDetails.opening_hours && (
                <div>
                  <h4>Horario de Apertura</h4>
                  <ul>
                    {placeDetails.opening_hours.weekday_text.map((time, index) => (
                      <li key={index}>{time}</li>
                    ))}
                  </ul>
                </div>
              )}
              <h4>Fotos</h4>
              {placeDetails.photos && (
                <div className="photo-container"> 
                  {placeDetails.photos.slice(0, 5).map((photo, index) => (
                    <img key={index} src={photo.getUrl()} alt="Lugar" className="photo" style={{ width: '200px', margin: '10px' }} />
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </LoadScript>
      {placeDetails ? 
      <ButtonHOTEL
        src={{hotel: BOTONHOTEL, hotelon: BOTONHOTELON}}
        alt="hotelBoton"
        className="botonhotelclass"
        estadoInvitado={invitadosReservaHotel}
      /> : <div><br></br><h1 className="botonrecarga" onClick={() => window.location.reload()} >🔄️</h1><br></br><p>Recargar página</p></div>
    }
      </div>
    );
  };
  
  export default GooglePlaceDetails;